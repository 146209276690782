const SET_NOTES = "@@SET_NOTES";
const SET_UNREAD_COUNT = "@@SET_UNREAD_COUNT";

const initialState = {
  notes: [],
  unreadCount: 0
};

function setNotes(notes) {
  return {
    type: SET_NOTES,
    notes
  };
}

function applySetNotes(state, action) {
  const { notes } = action;
  return {
    ...state,
    notes
  };
}

function setUnreadCount(count) {
  return {
    type: SET_UNREAD_COUNT,
    count
  };
}

function applySetUnreadCount(state, action) {
  const { count } = action;
  return {
    ...state,
    unreadCount: count
  };
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_NOTES:
      return applySetNotes(state, action);
    case SET_UNREAD_COUNT:
      return applySetUnreadCount(state, action);
    default:
      return state;
  }
}

const actionCreator = {
  setNotes,
  setUnreadCount
};

export { actionCreator as userNotesActions };

export default reducer; 