const MinigameMain = ({ selectedGame, onSelectHole }) => {

    const powerballBettingHole = [
        {type: "powerball_odd_even", typeNm: "파워볼 홀짝", rollingType: "dhpowerball", holeList: [
                {hole: "pODD", holeNm: "홀", odds: 1.95},
                {hole: "pEVEN", holeNm: "짝", odds: 1.95},
            ]},
        {type: "powerball_unover", typeNm: "파워볼 언더오버", rollingType: "dhpowerball", holeList: [
                {hole: "pUNDER", holeNm: "언더", odds: 1.95},
                {hole: "pOVER", holeNm: "오버", odds: 1.95},
            ]},
        {type: "sum_odd_even", typeNm: "일반볼 홀짝", rollingType: "dhpowerball", holeList: [
                {hole: "ODD", holeNm: "홀", odds: 1.95},
                {hole: "EVEN", holeNm: "짝", odds: 1.95},
            ]},
        {type: "sum_unover", typeNm: "일반볼 언더오버", rollingType: "dhpowerball", holeList: [
                {hole: "UNDER", holeNm: "언더", odds: 1.95},
                {hole: "OVER", holeNm: "오버", odds: 1.95},
            ]},
        {type: "sum_size", typeNm: "일반볼 구간", rollingType: "dhpowerball", holeList: [
            {hole: "S", holeNm: "소(15-64)", odds: 2.8},
            {hole: "M", holeNm: "중(65-80)", odds: 2.5},
            {hole: "L", holeNm: "대(81-130)", odds: 2.8},
        ]},
        {type: "powerball_odd_even_unover", typeNm: "P홀짝 + P언더오버", rollingType: "mix_dhpowerball", holeList: [
            {hole: "pODD_pUNDER", holeNm: "P홀+P언더", odds: 4.12},
            {hole: "pEVEN_pUNDER", holeNm: "P짝+P언더", odds: 3.02},
            {hole: "pODD_pOVER", holeNm: "P홀+P오버", odds: 3.02},
            {hole: "pEVEN_pOVER", holeNm: "P짝+P오버", odds: 4.12},
        ]},
        {type: "sum_odd_even_unover", typeNm: "홀짝 + 언더오버", rollingType: "mix_dhpowerball", holeList: [
            {hole: "ODD_UNDER", holeNm: "홀+언더", odds: 4.12},
            {hole: "EVEN_UNDER", holeNm: "짝+언더", odds: 3.02},
            {hole: "ODD_OVER", holeNm: "홀+오버", odds: 3.02},
            {hole: "EVEN_OVER", holeNm: "짝+오버", odds: 4.12},
        ]},
        {type: "sum_odd_even_unover_p_odd_even", typeNm: "홀짝 + 언더오버 + P홀짝", rollingType: "mix_dhpowerball", holeList: [
            {hole: "ODD_UNDER_pODD", holeNm: "홀+언더+P홀", odds: 2.8},
            {hole: "EVEN_UNDER_pODD", holeNm: "짝+언더+P홀", odds: 2.8},
            {hole: "ODD_OVER_pODD", holeNm: "홀+오버+P홀", odds: 2.8},
            {hole: "EVEN_OVER_pODD", holeNm: "짝+오버+P홀", odds: 2.8},
            {hole: "ODD_UNDER_pEVEN", holeNm: "홀+언더+P짝", odds: 2.8},
            {hole: "EVEN_UNDER_pEVEN", holeNm: "짝+언더+P짝", odds: 2.8},
            {hole: "ODD_OVER_pEVEN", holeNm: "홀+오버+P짝", odds: 2.8},
            {hole: "EVEN_OVER_pEVEN", holeNm: "짝+오버+P짝", odds: 2.8},
        ]},
        {type: "pb_number", typeNm: "파워볼 숫자", rollingType: "dhpowerball", holeList: [
            {hole: "N0", holeNm: "0", odds: 1.95},
            {hole: "N1", holeNm: "1", odds: 1.95},
            {hole: "N2", holeNm: "2", odds: 1.95},
            {hole: "N3", holeNm: "3", odds: 1.95},
            {hole: "N4", holeNm: "4", odds: 1.95},
            {hole: "N5", holeNm: "5", odds: 1.95},
            {hole: "N6", holeNm: "6", odds: 1.95},
            {hole: "N7", holeNm: "7", odds: 1.95},
            {hole: "N8", holeNm: "8", odds: 1.95},
            {hole: "N9", holeNm: "9", odds: 1.95},
        ]},
    ]
    
    const powerLadderBettingHole = [
        {type: "powerladder_start", typeNm: "출발", rollingType: "dhpowerladder", holeList: [
            {hole: "LS", holeNm: "좌출", odds: 1.95},
            {hole: "RS", holeNm: "우출", odds: 1.95},
        ]},
        {type: "powerladder_line", typeNm: "줄수", rollingType: "dhpowerladder", holeList: [
            {hole: "LINE3", holeNm: "3줄", odds: 1.95},
            {hole: "LINE4", holeNm: "4줄", odds: 1.95},
        ]},
        {type: "powerladder_odd_even", typeNm: "홀짝", rollingType: "dhpowerladder", holeList: [
            {hole: "ODD", holeNm: "홀", odds: 1.95},
            {hole: "EVEN", holeNm: "짝", odds: 1.95},
        ]},
        {type: "powerladder_start_line", typeNm: "출발+줄수", rollingType: "mix_dhpowerladder", holeList: [
            {hole: "LS_LINE3", holeNm: "좌출+3줄", odds: 1.95},
            {hole: "RS_LINE3", holeNm: "우출+3줄", odds: 1.95},
            {hole: "LS_LINE4", holeNm: "좌출+4줄", odds: 1.95},
            {hole: "RS_LINE4", holeNm: "우출+4줄", odds: 1.95},
        ]},
    ]

    // 선택된 게임에 따른 베팅홀 결정
    const getBettingHole = () => {
        if (!selectedGame) return [];
        
        switch (selectedGame.id) {
            case 'dhpowerball':
                return powerballBettingHole;
            case 'dhpowerladder':
                return powerLadderBettingHole;
            default:
                return [];
        }
    };

    return (
        <div className="w-full h-full min-h-[640px] bg-white rounded-lg">
            <iframe 
                src={selectedGame?.url || ''}
                title={selectedGame?.name || '미니게임'}
                className="w-full h-full"
                style={{
                    minHeight: '640px',
                    border: 'none'
                }}
                loading="lazy"
            />

            <div className="grid grid-cols-2 md:grid-cols-2 gap-2 py-4 bg-gray-50 rounded-lg">
                {getBettingHole().map((item, idx) => (
                    <div key={idx} className="flex flex-col bg-white rounded-lg shadow-sm p-3">
                        <div className="text-sm font-medium text-gray-700 mb-2 pb-2 border-b">
                            {item.typeNm}
                        </div>
                        <div className={`grid gap-2 ${
                            item.holeList.length === 2 ? 'grid-cols-2' : 
                            item.holeList.length === 3 ? 'grid-cols-3' : 
                            item.holeList.length === 4 ? 'grid-cols-4' : 
                            item.holeList.length === 8 ? 'grid-cols-4' : 
                            item.holeList.length === 10 ? 'grid-cols-5' : 'grid-cols-2'
                        }`}>
                            {item.holeList.map((hole, holeIdx) => (
                                <button 
                                    key={holeIdx} 
                                    onClick={() => {
                                        const betting = {
                                            id: selectedGame?.id || '',
                                            name: selectedGame?.name || '',
                                            gameType: item.type,
                                            gameName: item.typeNm,
                                            hole: hole.hole,
                                            holeName: hole.holeNm,
                                            odds_base: "0",
                                            rollingType: item.rollingType,
                                            holeOdds: hole.odds
                                        };
                                        onSelectHole(betting);
                                    }}
                                    className={`flex flex-col items-center justify-center p-2 rounded cursor-pointer
                                        transition-all duration-200
                                        ${item.holeList.length === 2 
                                            ? (holeIdx === 0 
                                                ? "bg-blue-50 hover:bg-blue-100 border border-blue-100" 
                                                : "bg-red-50 hover:bg-red-100 border border-red-100")
                                            : "bg-gray-50 hover:bg-gray-100 border border-gray-200"
                                        }`}
                                >
                                    <span className={`text-sm font-medium mb-1 ${
                                        item.holeList.length === 2 
                                            ? (holeIdx === 0 ? "text-blue-700" : "text-red-700")
                                            : "text-gray-700"
                                    }`}>
                                        {hole.holeNm}
                                    </span>
                                    <span className={`text-xs font-bold ${
                                        item.holeList.length === 2 
                                            ? (holeIdx === 0 ? "text-blue-600" : "text-red-600")
                                            : "text-gray-600"
                                    }`}>
                                        {hole.odds}
                                    </span>
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MinigameMain;