import Right from "./MinigameCart";
import {useState, useEffect} from "react";
import Loading from "../../component/Loading";
import {accountActions} from "../../redux/actions/account";
import {connect, useSelector} from "react-redux";
import * as api from "../../api/api";
import dayjs from "dayjs";
import {calcOdds} from "../biz/Betting";
import { XMarkIcon } from '@heroicons/react/24/outline';
import MinigameMain from "./MinigameMain";
import MinigameCategory from "./MinigameCategory";

const MinigameBetting = (props) => {
    const {user} = useSelector((props) => props.account);
    const [loading, setLoading] = useState(false);
    const [isMobileCartOpen, setIsMobileCartOpen] = useState(false);
    const [selectedGame, setSelectedGame] = useState({
        id: 'dhpowerball',
        name: '동행파워볼',
        url: 'https://dhpowerball.net/rpowerball/live.php'
    });
    const [myInfo, setMyInfo] = useState({
        myCash: 0, myPoint: 100000
    });
    const [round, setRound] = useState(1)
    const [dateRound, setDateRound] = useState(1)
    const [bettingList, setBettingList] = useState([]);
    const [bettingAmount, setBettingAmount] = useState(0);
    const [matchList, setMatchList] = useState([]);
    const [selectedHole, setSelectedHole] = useState(null);

    let currentPer = 1;

    // 배팅 목록이 있을 때 모바일 카트 자동 표시
    useEffect(() => {
        if (bettingList.length > 0) {
            setIsMobileCartOpen(true);
        } else {
            setIsMobileCartOpen(false);
        }
    }, [bettingList]);

    // useEffect 수정
    useEffect(() => {
        if (isMobileCartOpen) {
            // 모바일 카트가 열릴 때 body 스크롤 방지
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
        } else {
            // 모바일 카트가 닫힐 때 body 스크롤 복원
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.width = '';
        }
        
        return () => {
            // cleanup function
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.width = '';
        };
    }, [isMobileCartOpen]);

    const onSelectGame = (game) => {
        setSelectedGame(game);
    }

    const onInit = () => {
        setBettingList([])
        setBettingAmount(0)
        setSelectedHole(null)
    }

    const onInitCart = () => {
        setSelectedHole(null);
        // 기타 카트 관련 state 초기화
        setBettingAmount(0);
        setSelectedHole(null)
    };

    const onBettingCart = (betting) => {
        setBettingList([
            ...bettingList, betting
        ])
    }

    const onBettingAmount = (amount) => {
        setBettingAmount(amount)
    }

    const onBetting = async (betting) => {
        // 최대 100배까지만 배팅이 가능하다
        const maxPer = 100;

        // 현재 배당율 계산
        currentPer = bettingList.reduce((acc, curr) => acc * curr.odd_value, 1)

        if (currentPer > maxPer) {
            window.alert("최대 100배 까지 배팅이 가능합니다.")
            return;
        }

        if (bettingAmount <= 0) {
            window.alert("배팅 금액을 입력해주세요.")
            return;
        }

        console.log("### betting :: ", betting)

        if (window.confirm("위의 내역으로 배팅하시겠어요?")) {
            setLoading(true)
            sendBetting(betting)
        }
    }

    const sendBetting = async (betting) => {
        setLoading(true)

        const {data} = await api.getMinigameRound();

        // 추가 데이터 조립
        betting.round = parseInt(data.round) + 1;
        betting.dateRound = parseInt(data.gameRound) + 1;
        betting.instUser = user.userId;
        betting.updUser = user.userId;

        api.putMinigameBetting(betting).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                window.alert("배팅 처리가 완료되었습니다.")
                onInit()
            }
            else {
                window.alert(statusText)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onSelectHole = (betting) => {
        setSelectedHole(betting);
    };

    return (
        <div className="flex flex-col h-[calc(100vh-144px)] bg-gray-50">
            {loading ? <Loading /> : null}

            {/* 상단 스포츠 선택 영역 - 고정 */}
            <div className="flex-shrink-0 px-2 py-2 bg-white border-b border-gray-200">
                <div className="max-w-[1280px] mx-auto">
                    <MinigameCategory 
                        onSelectGame={onSelectGame} 
                        onInitCart={onInitCart}
                    />
                </div>
            </div>

            {/* 메인 컨텐츠 영역 */}
            <div className="flex-1 w-full overflow-hidden">
                <div className="max-w-[1280px] mx-auto relative h-full">
                    {/* 컨텐츠 영역 - 스크롤 가능 */}
                    <div className="md:mr-[300px] h-full">
                        <div className="h-full overflow-y-auto">
                            <div className="bg-white shadow-sm rounded-lg my-2">
                                <MinigameMain 
                                    selectedGame={selectedGame} 
                                    onSelectHole={onSelectHole}
                                />
                            </div>
                        </div>
                    </div>
                    
                    {/* 데스크톱 Right 컴포넌트 - 고정 */}
                    <div className="hidden md:block absolute top-0 right-0 w-[280px] h-full overflow-hidden">
                        <div className="h-full overflow-y-auto">
                            <Right 
                                init={onInit} 
                                info={myInfo} 
                                onBetting={onBetting}
                                bettingList={bettingList}
                                onBettingCart={onBettingCart}
                                onBettingAmount={onBettingAmount}
                                selectedGame={selectedGame}
                                selectedHole={selectedHole}
                                setSelectedHole={setSelectedHole}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* 모바일 배팅 카트 버튼 */}
            {bettingList.length > 0 && (
                <div className="md:hidden fixed bottom-4 left-0 right-0 px-4">
                    <button 
                        className="w-full py-3 bg-blue-500 text-white rounded-lg shadow-lg flex items-center justify-center gap-2"
                        onClick={() => setIsMobileCartOpen(true)}
                    >
                        <span className="font-bold">배팅카트</span>
                        <span className="bg-blue-600 px-2 py-1 rounded-full text-sm">
                            {bettingList.length}
                        </span>
                    </button>
                </div>
            )}

            {/* 모바일 슬라이드 업 배팅 카트 */}
            <div 
                className={`
                    md:hidden fixed inset-0 z-50 
                    transition-opacity duration-300 ease-in-out
                    ${isMobileCartOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}
                `}
            >
                <div 
                    className={`
                        absolute inset-0 bg-black 
                        transition-opacity duration-300 ease-in-out
                        ${isMobileCartOpen ? 'opacity-50' : 'opacity-0'}
                    `}
                    onClick={() => setIsMobileCartOpen(false)}
                />
                <div 
                    className={`
                        fixed bottom-0 left-0 right-0 
                        bg-white rounded-t-2xl 
                        transform transition-all duration-300 ease-in-out
                        max-h-[90vh] overflow-y-auto
                        ${isMobileCartOpen ? 'translate-y-0' : 'translate-y-full'}
                    `}
                >
                    {/* 헤더 */}
                    <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200">
                        <div className="flex items-center gap-2">
                            <span className="font-bold text-gray-800 text-lg">배팅카트</span>
                            <span className="bg-blue-500 text-white px-2.5 py-1 rounded-full text-sm font-medium">
                                {bettingList.length}
                            </span>
                        </div>
                        <button 
                            onClick={() => setIsMobileCartOpen(false)}
                            className="p-1.5 rounded-full hover:bg-gray-100 active:bg-gray-200"
                        >
                            <XMarkIcon className="w-6 h-6 text-gray-500" />
                        </button>
                    </div>

                    {/* 모바일용 Right 컴포넌트 */}
                    <div className="flex-1 overflow-y-auto">
                        <Right 
                            init={onInit} 
                            info={myInfo} 
                            onBetting={onBetting}
                            bettingList={bettingList}
                            onBettingCart={onBettingCart}
                            onBettingAmount={onBettingAmount}
                            selectedGame={selectedGame}
                            selectedHole={selectedHole}
                            setSelectedHole={setSelectedHole}
                            isMobile={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MinigameBetting)