import {connect, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import * as api from "../../../api/api";
import Loading from "../../../component/Loading";
import numeral from "numeral";
import dayjs from "dayjs";

const SlotBettingList = (props) => {
    const vendorName = (vendorCode) => {
        switch (vendorCode) {
            case "pragmatic_slot":
                return "프라그마틱 슬롯"
            case "pragmatic_casino" :
                return "프라그마틱 카지노"
        }
    }

    const {user} = useSelector((props) => props.account);
    const [loading, setLoading] = useState(false)
    const [bettingList, setBettingList] = useState([])

    useEffect(() => {
        search()
    }, [])

    const winning = (result) => {
        switch (result) {
            case 0:
                return "대기"
            case 1:
                return "승리"
            case 2:
                return "패배"
        }
    }

    const search = () => {
        const params = {
            companyCode: user.companyCode,
            userId: user.userId,
            vendorTypeCode: "slot"
        }

        setLoading(true)
        api.getUserSlotBettingList(params).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                setBettingList(data)
            }
        })
            .catch(ex => {
                console.error(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className={"flex-1 px-2 sm:px-4"}>
            {loading ? <Loading/> : null}
            {bettingList.length > 0 &&
            <div className={"flex-grow"}>
                {bettingList.map((betting, idx) => {
                    return (
                        <div key={String(idx)} className={"flex flex-col gap-2 rounded border-2 border-indigo-500 mb-4"}>
                            <div className={"flex flex-col sm:flex-row p-2 gap-1 sm:gap-2 items-start sm:items-center"}>
                                <span className={"text-sm text-gray-800 px-2 font-bold"}>{dayjs(betting.bettingDt).format("YYYY-MM-DD")}</span>
                                <span className={"w-full sm:w-[200px] text-sm sm:text-md text-gray-800 font-bold"}>{vendorName(betting.vendorCode)}</span>
                                <div className={"flex flex-row items-center min-w-[140px]"}>
                                    <span className={`w-[60px] text-sm font-bold text-center ${betting.status === 1 ? 'text-green-500' : 'text-red-500'}`}>
                                                        {winning(betting.status)}
                                                    </span>
                                </div>
                            </div>
                            <div className={"flex flex-col p-2 gap-1"}>
                                <div className={"flex flex-row"}>
                                    <span className={"text-xs font-bold"}>배팅시간 : {dayjs(betting.createdAt).format("YYYY-MM-DD HH:mm:ss")}</span>
                                    <span className={"text-xs font-bold px-2"}> / </span>
                                    <span className={"text-xs font-bold"}>배팅금액 : {numeral(betting.amount).format("0,0")}원</span>
                                </div>
                                <div className={"flex flex-row"}>
                                    <span className={"text-xs font-bold"}>당첨금 : <span className={"text-red-500"}>{betting.status === 1 ? numeral(betting.winAmount).format("0,0") : 0} 원</span></span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            }
        </div>
    )
}

const mapStateToProps = (state) =>
{
    const {account} = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(SlotBettingList)