import {useEffect, useState, useCallback} from "react";
import numeral from "numeral";
import {connect, useSelector} from "react-redux";
import * as api from "../../../api/api";
import dayjs from "dayjs";

const UserDepositRequest = (props) => {
    const {user} = useSelector((props) => props.account);
    const { siteConfig } = useSelector(state => state.siteConfig);
    const [deposit, setDeposit] = useState('');
    const [depositList, setDepositList] = useState([]);

    useEffect(() => {
        getUserDepositList();

        // 초기 충전 금액 설정
        if (siteConfig) {
            const unit = parseInt(siteConfig.chargeUnit || 0);
            const minAmount = parseInt(siteConfig.minChargeAmount || 0);
            setDeposit(minAmount.toString());
        }
    }, [siteConfig]);

    const validateAndAdjustAmount = useCallback((value) => {
        if (!value) return '';
        if (!siteConfig?.chargeUnit) return value;
        
        const numValue = parseInt(value);
        if (isNaN(numValue)) return value;
        
        const unit = parseInt(siteConfig.chargeUnit);
        const minAmount = parseInt(siteConfig.minChargeAmount || 0);
        
        // 최소 금액보다 작은 경우 최소 금액으로 조정
        if (numValue < minAmount) {
            return minAmount.toString();
        }
        
        // 배수 검증
        const remainder = numValue % unit;
        if (remainder === 0) return value;
        
        // 가장 가까운 배수로 조정
        const adjustedValue = Math.round(numValue / unit) * unit;
        return adjustedValue.toString();
    }, [siteConfig?.chargeUnit, siteConfig?.minChargeAmount]);

    const handleAmountChange = useCallback((e) => {
        const value = e.target.value;
        
        // 빈 값이거나 숫자만 있는 경우에만 허용
        if (value === '' || /^\d+$/.test(value)) {
            setDeposit(value);
        }
    }, []);

    const handleAmountBlur = useCallback((e) => {
        const value = e.target.value;
        if (value === '') return;
        
        const adjustedValue = validateAndAdjustAmount(value);
        setDeposit(adjustedValue);
    }, [validateAndAdjustAmount]);

    const onMoneyInput = useCallback((money) => {
        if (money === 0) {
            setDeposit('');
            return;
        }
        
        const unit = parseInt(siteConfig?.chargeUnit || 0);
        const minAmount = parseInt(siteConfig?.minChargeAmount || 0);
        
        // 최소 금액보다 작은 경우 최소 금액으로 조정
        if (money < minAmount) {
            setDeposit(minAmount.toString());
            return;
        }
        
        // 배수 검증
        const remainder = money % unit;
        if (remainder === 0) {
            setDeposit(money.toString());
        } else {
            // 가장 가까운 배수로 조정
            const adjustedValue = Math.round(money / unit) * unit;
            setDeposit(adjustedValue.toString());
        }
    }, [siteConfig?.chargeUnit, siteConfig?.minChargeAmount]);

    const getUserDepositList = () => {
        const params = {
            companyCode: user.companyCode,
            status: 0,
            userId: user.userId
        }
        api.getDepositList(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setDepositList(data)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {

            })
    }

    const requestDeposit = () => {
        if (depositList.length > 0) {
            window.alert("이전에 입금 신청한 건이 있습니다.")
            return
        }

        // 현재 시간 체크
        const now = dayjs();
        const currentTime = now.format("HHmm");
        const startTime = siteConfig?.exchangeStartTime || "0000";
        const endTime = siteConfig?.exchangeEndTime || "0000";

        // 시작/종료 시간이 모두 0000이면 시간 제한 없음
        if (startTime === "0000" && endTime === "0000") {
            // 시간 제한 없이 진행
        } else {
            // 현재 시간이 제한 시간대에 있는지 확인
            if (currentTime >= startTime && currentTime <= endTime) {
                console.log("### currentTime :: ", dayjs(currentTime).format("HH:mm"))
                console.log("### endTime :: ", dayjs(endTime))
                // window.alert(`${dayjs(endTime).format("hh:mm")} 이후에 신청해 주세요.`);
                return;
            }
        }

        if (window.confirm("입금 신청을 하시겠어요?")) {
            const params = {
                companyCode: user.companyCode,
                depositDt: dayjs().format("YYYYMMDD"),
                userId: user.userId,
                userName: user.bankOwner,
                amount: deposit,
                status: 0
            }

            api.putDeposit(params).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    window.alert("입금신청이 정상적으로 완료되었습니다.")
                }
                else {
                    window.alert(statusText)
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
        }
    }

    return (
        <div className={"w-full md:w-[1140px] flex flex-col bg-white"}>
            <img src={"https://14-ca.com/img/lang/kr/img_title_charge1.jpg"} alt={""} className={"w-full"}/>
            <div className={"flex flex-row p-2 justify-between border-b"}>
                <div className={"flex flex-row"}>
                    <span className={"text-sm text-gray-500"}>충전 {'>'} 보유머니 충전</span>
                </div>
            </div>
            <div className={"flex flex-col gap-1 p-3 border-b"}>
                <span className={"text-lg"}>i 확인 / 필독사항</span>
                <span className={"text-sm text-blue-500"}>반드시 등록된 계좌를 통해 입금 바랍니다.</span>
                <span className={"text-sm text-gray-500"}>은행 기관을 통한 점검 시간에는 입금이 불가하므로 해당 은행 점검시간을 피해 입금을 해주시기 바랍니다.</span>
                <span className={"text-sm text-gray-500"}>입금 계좌는 수시로 변경될 수 있으니 꼭 입금 전 계좌 문의 후 입금 계좌 확인 후에 입금 바랍니다.</span>
                <span className={"text-sm text-gray-500"}>그 밖에 전용 계좌 발급 및 문의사항은 고객센터를 통해 문의 바랍니다.</span>
            </div>
            <div className={"flex flex-col gap-1 p-3 border-b"}>
                <span className={"text-lg"}>입금 계좌 정보 확인</span>
                <span className={"text-sm text-gray-500"}>입금 계좌번호는 쪽지로 발송되며, 계좌번호로 선 입금 바랍니다.</span>
            </div>
            <div className={"flex flex-col gap-4 p-3"}>
                <div className={"flex flex-col md:flex-row items-start md:items-center gap-2"}>
                    <span className={"text-sm"}>계좌번호 문의</span>
                    <button className={"rounded text-sm text-white px-2 py-1 bg-green-500"}>계좌번호 신청</button>
                    <span className={"text-sm"}>계좌번호는 쪽지로 발송됩니다.</span>
                </div>
                <span className={"text-sm text-gray-500"}>위 안내된 계좌번호로 선입금 후 입금하신 금액을 정확히 입력하고 신청하기 버튼을 눌러주세요.</span>
                <div className={"flex flex-col gap-4 mt-4"}>
                    <div className={"flex flex-col md:flex-row gap-2 md:gap-4"}>
                        <span className={"text-sm text-gray-500"}>현재 보유머니</span>
                        <span className={"text-sm text-green-500 font-bold"}>{numeral(user.userCash).format("0")}원</span>
                    </div>
                    <div className={"flex flex-col md:flex-row gap-2 md:gap-4"}>
                        <span className={"text-sm text-gray-500"}>충전금액 입력</span>
                        <div className={"flex flex-col gap-2"}>
                            <input className={"w-full md:w-80 px-1 py-1 rounded-sm bg-white border border-yellow-200 text-black text-xs"}
                                   value={deposit}
                                   onChange={handleAmountChange}
                                   onBlur={handleAmountBlur}
                                   placeholder={"직접 입력은 이곳에 숫자만 입력해주세요."}/>
                            <div className={"grid grid-cols-3 md:grid-cols-8 gap-1"}>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-gray-500 rounded hover:bg-gray-600"} onClick={() => onMoneyInput(10000)}>1만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-gray-500 rounded hover:bg-gray-600"} onClick={() => onMoneyInput(30000)}>3만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-gray-500 rounded hover:bg-gray-600"} onClick={() => onMoneyInput(50000)}>5만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-green-500 rounded hover:bg-green-600"} onClick={() => onMoneyInput(100000)}>10만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-green-500 rounded hover:bg-green-600"} onClick={() => onMoneyInput(300000)}>30만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-green-500 rounded hover:bg-green-600"} onClick={() => onMoneyInput(500000)}>50만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-green-500 rounded hover:bg-green-600"} onClick={() => onMoneyInput(1000000)}>100만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-amber-500 rounded hover:bg-amber-600"} onClick={() => onMoneyInput(0)}>정정</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col items-center my-3"}>
                    <button className={"w-full md:w-[400px] py-3 bg-zinc-700 text-white font-bold rounded hover:bg-zinc-800"} onClick={requestDeposit}>보유머니 충전 신청하기</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(UserDepositRequest)