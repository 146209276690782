import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from '../../../api/api';
import { accountActions } from "../../../redux/actions/account";
import { connect, useSelector } from "react-redux";
import { useWebSocket } from '../../../hooks/useWebSocket';
import Loading from '../../../component/Loading';
import * as Global from '../../../constants/Global';

const UserLogin = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loginInfo, setLoginInfo] = useState({
        userId: '',
        password: ''
    });
    const { user } = useSelector((props) => props.account);
    const { subscribe, unsubscribe, isSubscribed, isConnected } = useWebSocket();

    // 웹소켓 연결 상태 감시 및 구독 처리
    useEffect(() => {
        const handleWebSocketReconnect = async () => {
            if (isConnected && user?.userId && !isSubscribed('all')) {
                try {
                    await subscribe('all', user.userId);
                    console.log('WebSocket reconnection subscription successful');
                } catch (wsError) {
                    console.error('WebSocket reconnection subscription error:', wsError);
                }
            }
        };

        handleWebSocketReconnect();
    }, [isConnected, user?.userId, subscribe, isSubscribed]);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setLoginInfo(prev => ({
            ...prev,
            [name]: value
        }));
    }, []);

    const login = useCallback(async () => {
        if (!loginInfo.userId || !loginInfo.password) {
            window.alert('아이디와 비밀번호를 입력해주세요.');
            return;
        }

        setLoading(true);
        try {
            const response = await api.login({
                ...loginInfo,
                companyCode: Global.companyCode
            });
            const { data, status } = response;
            
            if (status === 200) {
                if (Object.keys(data).length > 0) {
                    if (data.useYn === 1) {
                        if (data.status === 9) {
                            window.alert("회원 가입 대기상태 입니다. 고객센터에 문의하세요.");
                            return;
                        } else if (data.status === 2 || data.status === 3) {
                            window.alert("사용할 수 없는 계정입니다. 고객센터에 문의하세요.");
                            return;
                        }

                        if (data.blockYn === 1) {
                            window.alert("사용할 수 없는 계정입니다. 고객센터에 문의하세요.");
                            return;
                        }
                        
                        // 로그인 성공 처리
                        await props.setAccount(data);
                        
                        // 웹소켓 구독
                        try {
                            if (!isSubscribed('all')) {
                                await subscribe('all', data.userId);
                                console.log('WebSocket subscription successful');
                            }
                        } catch (wsError) {
                            console.error('WebSocket subscription error:', wsError);
                            // 웹소켓 에러가 발생해도 로그인 상태는 유지
                        }
                        
                        props.onMenu(0);
                    } else {
                        window.alert("사용할 수 없는 계정입니다. 고객센터에 문의하세요.");
                    }
                } else {
                    window.alert("ID와 패스워드를 다시 확인해주세요.");
                }
            } else {
                window.alert(response.statusText);
            }
        } catch (error) {
            console.error('Login error:', error);
            window.alert(error.message || '로그인 중 오류가 발생했습니다.');
        } finally {
            setLoading(false);
        }
    }, [loginInfo, props, subscribe, isSubscribed]);

    const register = useCallback(() => {
        navigate('/register');
    }, [navigate]);

    const logout = useCallback(() => {
        // 웹소켓 구독 취소
        unsubscribe('all');
        
        // localStorage 데이터 삭제
        localStorage.removeItem('userId');
        localStorage.removeItem('password');
        
        // 로그아웃 처리
        props.setAccount({});
        props.onMenu(0);
    }, [props, unsubscribe]);

    return (
        <div className="w-full bg-white flex flex-row justify-center">
            <div className="w-full max-w-[1280px] flex flex-col md:flex-row items-center justify-end p-2 px-1 gap-2 border-b border-gray-200">
                {loading ? <Loading /> : null}
                <div className="flex flex-row items-center gap-2 w-full md:w-auto justify-between md:justify-end">
                    <input 
                        className="flex-1 md:flex-none md:w-32 px-2 h-8 rounded-md bg-white border border-gray-300 text-gray-700 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        onChange={event => {
                            setLoginInfo((state) => ({
                                ...state, userId: event.target.value
                            }));
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                login();
                            }
                        }}
                        placeholder="아이디"
                        disabled={loading}
                    />
                    <input 
                        className="flex-1 md:flex-none md:w-32 px-2 h-8 rounded-md bg-white border border-gray-300 text-gray-700 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        type="password"
                        onChange={event => {
                            setLoginInfo((state) => ({
                                ...state, password: event.target.value
                            }));
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                login();
                            }
                        }}
                        placeholder="비밀번호"
                        disabled={loading}
                    />
                </div>
                <div className="flex flex-row items-center gap-2 w-full md:w-auto justify-between md:justify-end">
                    <button 
                        className="flex-1 md:flex-none h-8 px-4 rounded-md text-white text-sm bg-blue-600 hover:bg-blue-700 transition-colors duration-200"
                        onClick={login}
                        disabled={loading}
                    >
                        {loading ? '로그인 중...' : '로그인'}
                    </button>
                    <button 
                        className="flex-1 md:flex-none h-8 px-4 rounded-md text-gray-700 text-sm border border-gray-300 hover:bg-gray-50 transition-colors duration-200"
                        onClick={register}
                        disabled={loading}
                    >
                        회원가입
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UserLogin));