const SET_MESSENGER_LIST = "@@SET_MESSENGER_LIST";

function setMessengerList(messengerList) {
    return {
        type: SET_MESSENGER_LIST,
        messengerList
    };
}

function applySetMessengerList(state, action) {
    const { messengerList } = action;
    return {
        ...state,
        messengerList
    };
}

const initialState = {
    messengerList: []
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_MESSENGER_LIST:
            return applySetMessengerList(state, action);
        default:
            return state;
    }
}

const actionCreator = {
    setMessengerList
};

export { actionCreator as messengerActions };
export default reducer; 