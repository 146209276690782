// 액션 타입 정의
export const ODDS_UPDATE = 'ODDS_UPDATE';
export const MATCH_UPDATE = 'MATCH_UPDATE';

export const websocketActions = {
    connected: () => ({ type: 'WEBSOCKET_CONNECTED' }),
    disconnected: () => ({ type: 'WEBSOCKET_DISCONNECTED' }),
    messageReceived: (data) => ({
        type: 'WEBSOCKET_MESSAGE_RECEIVED',
        payload: data
    }),
    updateOdds: (matchId, odds) => ({
        type: 'UPDATE_ODDS',
        payload: {
            matchId,
            odds,
            timestamp: Date.now()
        }
    }),
    updateMatch: (matchData) => ({
        type: MATCH_UPDATE,
        payload: matchData
    })
};

const initialState = {
    connected: false,
    lastMessage: null,
    oddsUpdates: new Map(), // 배당 업데이트 저장
    matches: new Map(),      // 경기 정보 저장
    previousOdds: new Map()
};

export const websocketReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'WEBSOCKET_CONNECTED':
            return {
                ...state,
                connected: true
            };
        case 'WEBSOCKET_DISCONNECTED':
            return {
                ...state,
                connected: false
            };
        case 'WEBSOCKET_MESSAGE_RECEIVED':
            return {
                ...state,
                lastMessage: action.payload
            };
        case 'UPDATE_ODDS':
            const { matchId, odds, timestamp } = action.payload;
            const previousOdds = state.oddsUpdates.get(matchId) || odds;
            
            return {
                ...state,
                oddsUpdates: new Map(state.oddsUpdates).set(matchId, odds),
                previousOdds: new Map(state.previousOdds).set(matchId, previousOdds)
            };
        case 'MATCH_UPDATE':
            const newMatches = new Map(state.matches);
            newMatches.set(action.payload.id, action.payload);
            return {
                ...state,
                matches: newMatches
            };
        default:
            return state;
    }
}; 