import {useEffect, useState} from "react";
import numeral from "numeral";
import {connect, useSelector} from "react-redux";
import * as api from "../../../api/api";
import SportsBettingList from "../betting/SportsBettingList";
import DhPowerBallBettingList from "../betting/DhPowerBallBettingList";
import DhPowerLadderBettingList from "../betting/DhPowerLadderBettingList";
import UserDepositRequest from "./UserDepositRequest";
import UserDepositList from "./UserDepositList";

const UserDeposit = (props) => {
    const [activeTab, setActiveTab] = useState('request');

    return (
        <div className={"flex flex-col bg-white"}>
            <img src={"https://14-ca.com/img/lang/kr/img_title_charge1.jpg"} alt={""}/>
            <div className={"flex flex-row p-2 justify-between"}>
                <div className={"flex flex-row"}>
                    <span className={"text-sm text-black font-bold"}>충전 >> 보유머니 충전</span>
                </div>
            </div>
            {/* 탭 메뉴 */}
            <div className="border-b">
                <div className="flex">
                    <button
                        className={`px-4 py-2 text-sm font-medium ${
                            activeTab === 'request'
                                ? 'text-blue-600 border-b-2 border-blue-600'
                                : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('request')}
                    >
                        충전신청
                    </button>
                    <button
                        className={`px-4 py-2 text-sm font-medium ${
                            activeTab === 'history'
                                ? 'text-blue-600 border-b-2 border-blue-600'
                                : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('history')}
                    >
                        충전내역
                    </button>
                </div>
            </div>

            {/* 탭 컨텐츠 */}
            <div className={"mt-4"}>
                {activeTab === 'request' && <UserDepositRequest />}
                {activeTab === 'history' && <UserDepositList />}
            </div>
        </div>
    )
}

export default UserDeposit