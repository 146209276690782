export const companyCode = "0000";

// export const apiHost = "http://localhost:18080"
export const apiHost = "https://popobu.ngrok.app"
// export const wsHost = "ws://localhost:18080/ws"
export const wsHost = "wss://popobu.ngrok.app/ws"

export const MENU_HOME = 0;
export const MENU_BETTING = 1;
export const MENU_CUSTOMER = 2;
export const MENU_BOARD = 3;
export const MENU_BETTING_HISTORY = 4;
export const MENU_BETTING_RULE = 5;
export const MENU_GAME_GUIDE = 51;
export const MENU_BETTING_RESULT = 52;
export const MENU_NOTICE = 6;
export const MENU_BETTING_SPECIAL = 7;
export const MENU_CHARGE = 91;
export const MENU_WITHDRAW = 92;
export const MENU_EXCHANGE_POINT = 93;
export const MENU_ATTENDANCE = 94;
export const MENU_MINIGAME = 8;
export const MENU_CASINO = 9;
export const MENU_SLOT = 10;
export const MENU_NOTES = 13;