import {useEffect, useState, useCallback} from "react";
import numeral from "numeral";
import {connect, useSelector} from "react-redux";
import * as api from "../../../api/api";
import dayjs from "dayjs";

const UserWithdrawRequest = (props) => {
    const {user} = useSelector((props) => props.account);
    const { siteConfig } = useSelector(state => state.siteConfig);
    const [amount, setAmount] = useState('');
    const [password, setPassword] = useState("");
    const [withdrawList, setWithdrawList] = useState([]);

    useEffect(() => {
        getUserWithdrawList();
        
        // 전환 가능한 최대 금액 계산
        if (siteConfig) {
            const unit = parseInt(siteConfig.exchangeUnit || 0);
            const maxCash = parseInt(user?.userCash || 0);
            const maxPossibleAmount = Math.floor(maxCash / unit) * unit;
            
            // 최소 교환 금액과 비교하여 더 큰 값을 초기값으로 설정
            const minAmount = parseInt(siteConfig.minExchangeAmount || 0);
            const initialAmount = Math.max(minAmount, maxPossibleAmount);
            
            setAmount(initialAmount.toString());
        }
    }, [siteConfig, user?.userCash]);

    const validateAndAdjustAmount = useCallback((value) => {
        if (!value) return '';
        if (!siteConfig?.exchangeUnit) return value;
        
        const numValue = parseInt(value);
        if (isNaN(numValue)) return value;
        
        const unit = parseInt(siteConfig.exchangeUnit);
        const maxCash = parseInt(user?.userCash || 0);
        
        // 최대 가능 금액 계산 (보유금액을 초과하지 않는 가장 큰 배수)
        const maxPossibleAmount = Math.floor(maxCash / unit) * unit;
        
        // 최대값보다 큰 경우 최대 가능 금액으로 조정
        if (numValue > maxPossibleAmount) {
            return maxPossibleAmount.toString();
        }
        
        // 배수 검증
        const remainder = numValue % unit;
        if (remainder === 0) return value;
        
        // 가장 가까운 배수로 조정
        const adjustedValue = Math.round(numValue / unit) * unit;
        // 조정된 값이 최대 가능 금액을 초과하지 않도록 함
        return Math.min(adjustedValue, maxPossibleAmount).toString();
    }, [siteConfig?.exchangeUnit, user?.userCash]);

    const handleAmountChange = useCallback((e) => {
        const value = e.target.value;
        
        // 빈 값이거나 숫자만 있는 경우에만 허용
        if (value === '' || /^\d+$/.test(value)) {
            setAmount(value);
        }
    }, []);

    const handleAmountBlur = useCallback((e) => {
        const value = e.target.value;
        if (value === '') return;
        
        const adjustedValue = validateAndAdjustAmount(value);
        setAmount(adjustedValue);
    }, [validateAndAdjustAmount]);

    const onMoneyInput = useCallback((money) => {
        if (money === 0) {
            setAmount('');
            return;
        }
        
        const unit = parseInt(siteConfig?.exchangeUnit || 0);
        const maxCash = parseInt(user?.userCash || 0);
        const maxPossibleAmount = Math.floor(maxCash / unit) * unit;
        
        // 입력된 금액이 최대 가능 금액을 초과하는지 확인
        if (money > maxPossibleAmount) {
            setAmount(maxPossibleAmount.toString());
            return;
        }
        
        // 배수 검증
        const remainder = money % unit;
        if (remainder === 0) {
            setAmount(money.toString());
        } else {
            // 가장 가까운 배수로 조정
            const adjustedValue = Math.round(money / unit) * unit;
            setAmount(Math.min(adjustedValue, maxPossibleAmount).toString());
        }
    }, [siteConfig?.exchangeUnit, user?.userCash]);

    const getUserWithdrawList = () => {
        const params = {
            companyCode: user.companyCode,
            status: 0,
            userId: user.userId
        }
        api.getWithdrawList(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setWithdrawList(data)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {

            })
    }

    const requestWithdraw = () => {
        // 현재 금액과 환전 신청 금액을 비교
        if (amount <= 0) {
            window.alert("출금 금액을 입력해주세요.")
            return
        }
        
        if (amount > user.userCash) {
            window.alert("보유하신 금액보다 많은 금액을 출금할 수 없습니다.")
            return
        }

        if (withdrawList.length > 0) {
            window.alert("이전에 출금 신청한 건이 있습니다.")
            return
        }

        if (window.confirm("출금 신청을 하시겠어요?")) {
            const params = {
                companyCode: user.companyCode,
                withdrawDt: dayjs().format("YYYYMMDD"),
                userId: user.userId,
                userName: user.bankOwner,
                amount: amount,
                status: 0,
                bankOwner: user.bankOwner,
                bankAccount: user.bankAccount,
                bankName: user.bankName
            }
            console.log("### params :: ", params)

            api.putWithdraw(params).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    window.alert("출금 신청이 정상적으로 완료되었습니다.")
                    return
                }
                else {
                    window.alert(statusText)
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {

                })
        }
    }

    return (
        <div className={"w-full md:w-[1140px] flex flex-col bg-white"}>
            <img src={"https://14-ca.com/img/lang/kr/img_title_charge1.jpg"} alt={""} className={"w-full"}/>
            <div className={"flex flex-row p-2 justify-between border-b"}>
                <div className={"flex flex-row"}>
                    <span className={"text-sm text-gray-500"}>환전 {'>'} 보유머니 환전</span>
                </div>
            </div>
            <div className={"flex flex-col gap-1 p-3 border-b"}>
                <span className={"text-lg"}>i 확인 / 필독사항</span>
                <span className={"text-sm text-blue-500"}>반드시 등록된 계좌를 통해 출금 처리가 되며 등록된 계좌 외에는 절대 출금 처리가 불가합니다.</span>
                <span className={"text-sm text-gray-500"}>은행 기관을 통한 점검 시간에는 출금이 불가하므로 해당 은행 점검시간을 피해 출금을 해주시기 바랍니다.</span>
                <span className={"text-sm text-gray-500"}>그 밖에 문의사항은 고객센터를 통해 문의 바랍니다.</span>
            </div>
            <div className={"flex flex-col gap-1 p-3 border-b"}>
                <span className={"text-lg"}>환전 금액 입력</span>
                <span className={"text-sm text-gray-500"}>환전 신청을 완료하게 되면 보유머니에서 신청한 금액만큼 차감되며 고객님의 계좌번호로 입금됩니다.</span>

                <div className={"flex flex-col gap-4 mt-4"}>
                    <div className={"flex flex-col md:flex-row gap-2 md:gap-4"}>
                        <span className={"text-sm text-gray-500"}>현재 보유머니</span>
                        <span className={"text-sm text-green-500 font-bold"}>{numeral(user.userCash).format("0,0")}원</span>
                    </div>
                    <div className={"flex flex-col md:flex-row gap-2 md:gap-4"}>
                        <span className={"text-sm text-gray-500"}>환전금액 입력</span>
                        <input className={"w-full md:w-80 px-1 py-1 rounded-sm bg-white border border-yellow-200 text-black text-xs"}
                               value={amount}
                               onChange={handleAmountChange}
                               onBlur={handleAmountBlur}
                               placeholder={"직접 입력은 이곳에 숫자만 입력해주세요."}/>
                    </div>
                    <div className={"flex flex-col md:flex-row gap-2 md:gap-4"}>
                        <span className={"text-sm text-gray-500"}>출금 비밀번호</span>
                        <div className={"flex flex-col gap-2"}>
                            <input className={"w-full md:w-80 px-1 py-1 rounded-sm bg-white border border-yellow-200 text-black text-xs"}
                                   type={"password"}
                                   value={password}
                                   onChange={event => {
                                       setPassword(event.target.value)
                                   }}
                                   placeholder={"비밀번호를 입력하세요"}/>
                            <div className={"grid grid-cols-3 md:grid-cols-9 gap-1"}>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-gray-500 rounded hover:bg-gray-600"} onClick={() => onMoneyInput(10000)}>1만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-gray-500 rounded hover:bg-gray-600"} onClick={() => onMoneyInput(30000)}>3만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-gray-500 rounded hover:bg-gray-600"} onClick={() => onMoneyInput(50000)}>5만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-green-500 rounded hover:bg-green-600"} onClick={() => onMoneyInput(100000)}>10만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-green-500 rounded hover:bg-green-600"} onClick={() => onMoneyInput(300000)}>30만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-green-500 rounded hover:bg-green-600"} onClick={() => onMoneyInput(500000)}>50만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-green-500 rounded hover:bg-green-600"} onClick={() => onMoneyInput(1000000)}>100만원</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-green-500 rounded hover:bg-green-600"} onClick={() => onMoneyInput(user.userCash)}>전액</button>
                                <button className={"px-2 py-1 text-sm text-white font-bold bg-amber-500 rounded hover:bg-amber-600"} onClick={() => onMoneyInput(0)}>정정</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col items-center my-3"}>
                    <button className={"w-full md:w-[400px] py-3 bg-zinc-700 text-white font-bold rounded hover:bg-zinc-800"} onClick={requestWithdraw}>보유머니 환전 신청하기</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(UserWithdrawRequest)