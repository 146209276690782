import {useEffect, useState} from "react";
import WidgetGameScheduleRow from "./WidgetGameScheduleRow";
import WidgetBoardRow from "./WidgetBoardRow";

const WidgetBoard = (props) => {
    const [isNotice, setIsNotice] = useState(true)

    const toggle = (value) => {
        setIsNotice(value)
    }

    return (
        <div className={"w-full rounded-xl border border-gray-200 bg-white p-4 shadow-sm"}>
            <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-bold text-gray-800">공지사항</h2>
            </div>
            <div className={"flex flex-col my-4"}>
                {props.list.map((item, idx) => {
                    return <WidgetBoardRow key={String(idx)} data={item} notice={isNotice} />
                })}
            </div>
        </div>
    )
}

export default WidgetBoard