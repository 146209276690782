import {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import * as api from "../../../api/api";
import SportsBettingList from "./SportsBettingList";
import DhPowerBallBettingList from "./DhPowerBallBettingList";
import DhPowerLadderBettingList from "./DhPowerLadderBettingList";
import CasinoBettingList from "./CasinoBettingList";
import SlotBettingList from "./SlotBettingList";

const MyBettingList = (props) => {
    const [activeTab, setActiveTab] = useState('sports');

    return (
        <div className={"w-full max-w-[1140px] mx-auto flex flex-col bg-white"}>
            <img src={"https://14-ca.com/img/lang/kr/img_title_mypage.jpg"} alt={""} className="w-full"/>
            <div className={"flex flex-row p-2 justify-between"}>
                <div className={"flex flex-row"}>
                    <span className={"font-bold text-xs sm:text-sm text-black"}>{"마이페이지 >> 배팅내역"}</span>
                </div>
            </div>

            {/* 탭 메뉴 */}
            <div className="border-b">
                <div className="flex">
                    <button
                        className={`px-4 py-2 text-sm font-medium ${
                            activeTab === 'sports'
                                ? 'text-blue-600 border-b-2 border-blue-600'
                                : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('sports')}
                    >
                        스포츠
                    </button>
                    <button
                        className={`px-4 py-2 text-sm font-medium ${
                            activeTab === 'dhpowerball'
                                ? 'text-blue-600 border-b-2 border-blue-600'
                                : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('dhpowerball')}
                    >
                        동행파워볼
                    </button>
                    <button
                        className={`px-4 py-2 text-sm font-medium ${
                            activeTab === 'dhpowerladder'
                                ? 'text-blue-600 border-b-2 border-blue-600'
                                : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('dhpowerladder')}
                    >
                        동행파워사다리
                    </button>
                    <button
                        className={`px-4 py-2 text-sm font-medium ${
                            activeTab === 'casino'
                                ? 'text-blue-600 border-b-2 border-blue-600'
                                : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('casino')}
                    >
                        카지노
                    </button>
                    <button
                        className={`px-4 py-2 text-sm font-medium ${
                            activeTab === 'slot'
                                ? 'text-blue-600 border-b-2 border-blue-600'
                                : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('slot')}
                    >
                        슬롯
                    </button>
                </div>
            </div>

            {/* 탭 컨텐츠 */}
            <div className={"mt-4"}>
                {activeTab === 'sports' && <SportsBettingList />}
                {activeTab === 'dhpowerball' && <DhPowerBallBettingList />}
                {activeTab === 'dhpowerladder' && <DhPowerLadderBettingList />}
                {activeTab === 'casino' && <CasinoBettingList />}
                {activeTab === 'slot' && <SlotBettingList />}
            </div>
        </div>
    )
}

export default MyBettingList