import React, {Suspense} from "react";
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";
import {Provider, useSelector} from "react-redux";
import configureStore from "./redux/store"
import './App.css';
import "./assets/style/style.scss";
import Splash from "./views/main/Splash"
import Login from "./views/login/Login";
import Frame from "./views/main/Frame";
import UserRegister from "./views/main/UserRegister";
import Login2 from "./views/login/Login2";
import Inspection from "./views/main/Inspection";
import { PersistGate } from 'redux-persist/integration/react';
import { useWebSocket } from './hooks/useWebSocket';
import {wsHost} from "./constants/Global";

const { store, persistor } = configureStore();

// 웹소켓 상태 모니터링 컴포넌트
const WebSocketMonitor = () => {
    const { connected } = useSelector(state => state.websocket);    
    
    React.useEffect(() => {
    }, [connected]);
    
    return null;
};

// WebSocket 연결을 관리할 컴포넌트 수정
const WebSocketProvider = ({ children }) => {
    useWebSocket(true);  // 항상 연결 시도 (shouldConnect = true)
    return children;
};

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <WebSocketProvider>
                    <HashRouter>
                        <Suspense>
                            <Routes>
                                <Route path={"/"} name={"Splash"} element={<Splash />} />
                                <Route path={"/main"} name={"Frame"} element={<Frame />} />
                                {/*<Route path={"/login"} name={"Login"} element={<Login />}/>*/}
                                <Route path={"/login"} name={"Login"} element={<Login2 />}/>
                                {/*<Route path={"/a/main"} name={"MainA"} element={<MainA />} />*/}
                                {/*<Route path={"/b/main"} name={"MainB"} element={<MainB />} />*/}
                                {/*<Route path={"/c/main"} name={"MainC"} element={<MainC />} />*/}
                                <Route path={"/register"} name={"Register"} element={<UserRegister />} />
                                <Route path={"/inspect"} name={"Inspection"} element={<Inspection />} />
                            </Routes>
                        </Suspense>
                    </HashRouter>
                </WebSocketProvider>
            </PersistGate>
        </Provider>
    )
}

export default App
