import {connect, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import * as api from "../../../api/api";
import Loading from "../../../component/Loading";
import numeral from "numeral";

const SportsBettingList = (props) => {
    const {user} = useSelector((props) => props.account);
    const [loading, setLoading] = useState(false)
    const [bettingList, setBettingList] = useState([])

    useEffect(() => {
        search()
    }, [])

    const winning = (result) => {
        switch (result) {
            case 0:
                return "대기"
            case 1:
                return "승"
            case 2:
                return "패"
            case 88:
                return "적특"
            case 99:
                return "취소"
        }
    }

    const search = () => {
        setLoading(true)
        api.getUserBettingList(user.userId).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                setBettingList(data)
            }
        })
            .catch(ex => {
                console.error(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const cancel = (betting) => {
        if (window.confirm("베팅을 취소하시겠어요?")) {

            setLoading(true)
            api.cancelBetting(betting).then(result => {
                const {data, status, statusText} = result;
                if (status === 200) {
                    const success = data.success;
                    if (success) {
                        window.alert(data.message)
                        search();
                    }
                    else {
                        window.alert(data.message)
                    }
                }
            })
                .catch(ex => {
                    console.error(ex.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <div className={"flex-1 px-2 sm:px-4"}>
            {loading ? <Loading/> : null}
            {bettingList.length > 0 &&
            <div className={"flex-grow"}>
                {bettingList.map((betting, idx) => {
                    return (
                        <div key={String(idx)} className={"flex flex-col gap-2 rounded border-2 border-indigo-500 mb-4"}>
                            {betting.detailList.map((detail, idx) => {
                                return (
                                    <div key={String(idx)} className={"flex flex-col gap-1 sm:gap-2"}>
                                        <div className={"flex flex-row bg-zinc-800 justify-between p-2"}>
                                            <div className={"flex flex-row gap-1 sm:gap-2 items-center"}>
                                                <span className={"w-12 sm:w-16 rounded-1 bg-indigo-500 text-[10px] sm:text-xs text-white text-center px-1 py-1 font-bold"}>{detail.sport}</span>
                                                <img className={"w-6 h-6 sm:w-8 sm:h-8"} src={detail.ccImage} alt={""}/>
                                                <span className={"text-sm sm:text-md text-white font-bold"}>{detail.ccKr}</span>
                                            </div>
                                            <div className={"flex flex-row items-center"}>
                                                <span className={"text-xs sm:text-sm text-white"}>{betting.instTime}</span>
                                            </div>
                                        </div>
                                        {detail.resultDiv === '10' &&
                                        <div className={"flex flex-col sm:flex-row p-2 gap-1 sm:gap-2 items-start sm:items-center"}>
                                            <span className={"text-sm text-gray-800 px-2"}>{detail.time}</span>
                                            <span className={"w-full sm:w-[100px] text-sm sm:text-md text-gray-800 font-bold"}>{detail.marketName}</span>
                                            <div className={"flex flex-1 flex-row justify-between gap-1"}>
                                                <button className={`flex-1 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Home' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.homeName}</button>
                                                <button className={`w-16 sm:w-20 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Draw' ? 'bg-red-500 border-red-500 text-white' : null}`}>Draw</button>
                                                <button className={`flex-1 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Away' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.awayName}</button>
                                            </div>
                                            <div className={"flex flex-row items-center min-w-[140px]"}>
                                                    <span className={"text-sm font-bold text-gray-600 w-[80px] text-center"}>
                                                        {detail.statusId === 2
                                                            ? `${detail.homeScore} : ${detail.awayScore}`
                                                            : detail.statusKr
                                                        }
                                                    </span>
                                                <span className={`w-[60px] text-sm font-bold text-center ${
                                                    detail.result === 1 || detail.result === 88 ? 'text-green-500' : 
                                                    detail.result === 2 ? 'text-red-500' : 
                                                    detail.result === 99 ? 'text-gray-500' : 'text-black'
                                                }`}>
                                                    {winning(detail.result)}
                                                </span>
                                            </div>
                                        </div>
                                        }
                                        {detail.resultDiv === '20' &&
                                        <div className={"flex flex-col sm:flex-row p-2 gap-1 sm:gap-2 items-start sm:items-center"}>
                                            <span className={"text-sm text-gray-800 px-2"}>{detail.time}</span>
                                            <span className={"w-full sm:w-[100px] text-sm sm:text-md text-gray-800 font-bold"}>{detail.marketName}</span>
                                            <div className={"flex flex-1 flex-row justify-between gap-1"}>
                                                <button className={`flex-1 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Over' ? 'bg-red-500 border-red-500 text-white' : null}`}>
                                                    <div className={"flex flex-row gap-1 justify-between"}>
                                                        <span className={"text-sm"}>{detail.homeName}</span>
                                                        <span className={"text-sm"}>오버</span>
                                                    </div>
                                                </button>
                                                <button className={`w-16 sm:w-20 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Draw' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.oddsBase}</button>
                                                <button className={`flex-1 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Under' ? 'bg-red-500 border-red-500 text-white' : null}`}>
                                                    <div className={"flex flex-row gap-1 justify-between"}>
                                                        <span className={"text-sm"}>언더</span>
                                                        <span className={"text-sm"}>{detail.awayName}</span>
                                                    </div>
                                                </button>
                                            </div>
                                            <div className={"flex flex-row items-center min-w-[140px]"}>
                                                    <span className={"text-sm font-bold text-gray-600 w-[80px] text-center"}>
                                                        {detail.statusId === 2
                                                            ? `${detail.homeScore} : ${detail.awayScore}`
                                                            : detail.statusKr
                                                        }
                                                    </span>
                                                <span className={`w-[60px] text-sm font-bold text-center ${
                                                    detail.result === 1 || detail.result === 88 ? 'text-green-500' : 
                                                    detail.result === 2 ? 'text-red-500' : 
                                                    detail.result === 99 ? 'text-gray-500' : 'text-black'
                                                }`}>
                                                        {winning(detail.result)}
                                                    </span>
                                            </div>
                                        </div>
                                        }
                                        {detail.resultDiv === '30' &&
                                        <div className={"flex flex-col sm:flex-row p-2 gap-1 sm:gap-2 items-start sm:items-center"}>
                                            <span className={"text-sm text-gray-800 px-2"}>{detail.time}</span>
                                            <span className={"w-full sm:w-[100px] text-sm sm:text-md text-gray-800 font-bold"}>{detail.marketName}</span>
                                            <div className={"flex flex-1 flex-row justify-between gap-1"}>
                                                <button className={`flex-1 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Home' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.homeName}</button>
                                                <button className={`w-16 sm:w-20 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Draw' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.oddsBase}</button>
                                                <button className={`flex-1 rounded text-sm sm:text-md text-gray-800 border-2 p-1 ${detail.oddsName === 'Away' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.awayName}</button>
                                            </div>
                                            <div className={"flex flex-row items-center min-w-[140px]"}>
                                                    <span className={"text-sm font-bold text-gray-600 w-[80px] text-center"}>
                                                        {detail.statusId === 2
                                                            ? `${detail.homeScore} : ${detail.awayScore}`
                                                            : detail.statusKr
                                                        }
                                                    </span>
                                                <span className={`w-[60px] text-sm font-bold text-center ${
                                                    detail.result === 1 || detail.result === 88 ? 'text-green-500' : 
                                                    detail.result === 2 ? 'text-red-500' : 
                                                    detail.result === 99 ? 'text-gray-500' : 'text-black'
                                                }`}>
                                                        {winning(detail.result)}
                                                    </span>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                )
                            })}
                            <div className={"h-1 border-1"} />
                            <div className={"flex flex-row p-2 items-center justify-between"}>
                                <div className={"flex flex-col gap-1"}>
                                    <div className={"flex flex-row"}>
                                        <span className={"text-xs font-bold"}>베팅시간 : {betting.instTime}</span>
                                        <span className={"text-xs font-bold px-2"}> / </span>
                                        <span className={"text-xs font-bold"}>배당율 : {betting.bettingOdds}</span>
                                        <span className={"text-xs font-bold px-2"}> / </span>
                                        <span className={"text-xs font-bold"}>베팅금액 : {numeral(betting.bettingAmount).format("0,0")}원</span>
                                    </div>
                                    <div className={"flex flex-row"}>
                                        <span className={"text-xs font-bold"}>예상 적중금액 : {numeral(betting.bettingAmount * betting.bettingOdds).format("0,0")} 원</span>
                                        <span className={"text-xs font-bold px-2"}> / </span>
                                        <span className={"text-xs font-bold"}>당첨금 : <span className={"text-red-500"}>{betting.result === 1 ? numeral(betting.bettingAmount * betting.bettingOdds).format("0,0") : 0} 원</span></span>
                                    </div>
                                </div>
                                {betting.result === 0 &&
                                <button className={"p-2 rounded bg-gray-500 text-white font-bold"} onClick={() => cancel(betting)}>베팅 취소</button>
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
            }
        </div>
    )
}

const mapStateToProps = (state) =>
{
    const {account} = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(SportsBettingList)