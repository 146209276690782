import { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { websocketActions } from '../redux/actions/websocket';
import {wsHost} from "../constants/Global";
import { accountActions } from '../redux/actions/account';

// 전역 웹소켓 인스턴스
let globalWs = null;

export const useWebSocket = (shouldConnect = false) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.account);
    const subscriptions = useRef(new Map());
    const pendingSubscriptions = useRef(new Set());
    const reconnectAttempts = useRef(0);
    const reconnectTimeoutRef = useRef(null);
    const MAX_RECONNECT_ATTEMPTS = 5;
    const INITIAL_RECONNECT_DELAY = 1000; // 1초
    const MAX_RECONNECT_DELAY = 30000;    // 30초
    const [activeSubscriptions, setActiveSubscriptions] = useState(new Set());

    // 재연결 딜레이 계산 함수 정의
    const getReconnectDelay = useCallback(() => {
        const delay = Math.min(
            INITIAL_RECONNECT_DELAY * Math.pow(2, reconnectAttempts.current),
            MAX_RECONNECT_DELAY
        );
        return delay + Math.random() * 1000; // 지터 추가
    }, []);

    const connect = useCallback(() => {
        if (globalWs?.readyState === WebSocket.OPEN) {
            return;
        }

        if (globalWs?.readyState === WebSocket.CONNECTING) {
            return;
        }

        try {
            globalWs = new WebSocket(wsHost);

            globalWs.onopen = () => {
                dispatch(websocketActions.connected());
                reconnectAttempts.current = 0;
                
                // 대기 중인 구독 요청 처리
                if (pendingSubscriptions.current.size > 0) {
                    pendingSubscriptions.current.forEach(topic => {
                        subscribe(topic);
                    });
                    pendingSubscriptions.current.clear();
                }
            };

            globalWs.onclose = (event) => {
                dispatch(websocketActions.disconnected());

                if (shouldConnect) {
                    const delay = getReconnectDelay();
                    if (reconnectTimeoutRef.current) {
                        clearTimeout(reconnectTimeoutRef.current);
                    }
                    
                    reconnectTimeoutRef.current = setTimeout(() => {
                        reconnectAttempts.current++;
                        connect();
                    }, delay);
                }
            };

            globalWs.onerror = (error) => {
                console.error('[WebSocket] Error occurred:', {
                    error,
                    readyState: globalWs?.readyState,
                    timestamp: new Date().toISOString(),
                    url: wsHost
                });
            };

            globalWs.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    console.log("### data :: ", data)

                    switch(data.type) {
                        case 'POINT_UPDATE':
                            dispatch(accountActions.updateUserPoint(data.totalPoint));
                            switch (data.reason) {
                                case 'deposit':
                                    window.alert("입금 신청이 처리되었습니다.");
                                    break;
                                case 'cancel':
                                    window.alert("출금 신청이 거절 처리되었습니다.");
                                    break;
                            }
                            break;
                        case 'CASH_UPDATE':
                            dispatch(accountActions.updateUserCash(data.totalCash));
                            switch (data.reason) {
                                case 'deposit':
                                    window.alert("입금 신청이 처리되었습니다.");
                                    break;
                                case 'cancel':
                                    window.alert("출금 신청이 거절 처리되었습니다.");
                                    break;
                            }
                            break;
                        case 'ODDS_UPDATE':
                            // 기존 배당률 업데이트 처리
                            if (Array.isArray(data)) {
                                data.forEach(update => {
                                    dispatch(websocketActions.updateOdds(
                                        update.id,
                                        {
                                            marketId: update.marketId,
                                            oddsHomeValue: update.homeValue,
                                            oddsDrawValue: update.drawValue,
                                            oddsAwayValue: update.awayValue,
                                            oddsBase: update.oddsBase,
                                            oddsStop: update.oddsStop
                                        }
                                    ));
                                });
                            }
                            break;
                        default:

                    }
                } catch (error) {
                    console.error('[WebSocket] Error processing message:', error);
                }
            };

        } catch (error) {
            console.error('[WebSocket] Connection failed:', error.message);
            
            if (shouldConnect) {
                const delay = getReconnectDelay();
                
                if (reconnectTimeoutRef.current) {
                    clearTimeout(reconnectTimeoutRef.current);
                }
                
                reconnectTimeoutRef.current = setTimeout(() => {
                    reconnectAttempts.current++;
                    connect();
                }, delay);
            }
        }
    }, [dispatch, shouldConnect, getReconnectDelay]);

    const subscribe = useCallback((topic, userId = '') => {
        if (globalWs?.readyState !== WebSocket.OPEN) {
            pendingSubscriptions.current.add(topic);
            return;
        }

        const message = {
            "type": "subscribe",
            "topic": topic,
            "userId": userId || user?.userId || ''
        };

        globalWs.send(JSON.stringify(message));
        subscriptions.current.set(topic, true);
        setActiveSubscriptions(prev => new Set([...prev, topic]));
    }, [user]);

    const unsubscribe = useCallback((topic) => {
        if (globalWs?.readyState !== WebSocket.OPEN) {
            return;
        }

        const message = {
            "type": "unsubscribe",
            "topic": topic
        };

        globalWs.send(JSON.stringify(message));
        subscriptions.current.delete(topic);
        setActiveSubscriptions(prev => {
            const newSet = new Set(prev);
            newSet.delete(topic);
            return newSet;
        });
    }, []);

    const isSubscribed = useCallback((topic) => {
        return activeSubscriptions.has(topic);
    }, [activeSubscriptions]);

    useEffect(() => {
        if (shouldConnect) {
            connect();
        }

        return () => {
            subscriptions.current.clear();
        };
    }, [shouldConnect, connect]);

    return { 
        subscribe, 
        unsubscribe, 
        isSubscribed,
        activeSubscriptions: Array.from(activeSubscriptions) 
    };
}; 