import {connect} from "react-redux";
import {useState} from "react";

const Customer = (props) => {
    const [searchWhere, setSearchWhere] = useState({
        userId: "", searchValue: "", category: ""
    })
    const [list, setList] = useState([])

    return (
        <div className={"w-full md:w-[1140px] flex flex-col bg-white"}>
            <img src={"https://14-ca.com/img/lang/kr/img_title_cs.jpg"} alt={""} className={"w-full"}/>
            <div className={"flex flex-col md:flex-row p-2 justify-between border-b"}>
                <div className={"flex flex-row mb-2 md:mb-0"}>
                    <span className={"text-sm text-gray-500"}>고객센터 {'>'} 1:1문의</span>
                </div>
                <div className={"flex flex-row gap-1"}>
                    <input className={"w-full md:w-auto px-2 py-1 border rounded text-sm"}
                           placeholder={"검색"}
                    />
                    <select className={"px-4 py-1 border rounded text-sm"}>
                        <option>전체</option>
                        <option>제목</option>
                        <option>내용</option>
                    </select>
                    <button className={"w-24 bg-blue-500 rounded text-white text-sm font-bold px-4 hover:bg-blue-600"}>검색</button>
                </div>
            </div>
            <div className={"flex flex-col p-2"}>
                <div className={"hidden md:flex flex-row bg-gray-200 gap-2"}>
                    <span className={"w-[88px] py-2 text-sm text-center"}>확인</span>
                    <span className={"w-[88px] py-2 text-sm text-center"}>종류</span>
                    <span className={"w-[593px] py-2 text-sm text-center"}>제목</span>
                    <span className={"w-[110px] py-2 text-sm text-center"}>상태</span>
                    <span className={"w-[132px] py-2 text-sm text-center"}>등록일</span>
                    <span className={"w-[88px] py-2 text-sm text-center"}>삭제</span>
                </div>
                {/* 모바일용 헤더 */}
                <div className={"md:hidden flex flex-row bg-gray-400 gap-2"}>
                    <span className={"w-[60px] py-2 text-sm text-center"}>확인</span>
                    <span className={"w-[60px] py-2 text-sm text-center"}>종류</span>
                    <span className={"flex-1 py-2 text-sm text-center"}>제목</span>
                    <span className={"w-[60px] py-2 text-sm text-center"}>상태</span>
                </div>
            </div>
            <div className={"flex flex-col md:flex-row justify-between p-2 gap-2"}>
                <button className={"rounded bg-blue-500 px-4 py-1 text-white text-sm hover:bg-blue-600"}>문의 작성</button>
                <div className={"flex flex-row gap-1 flex-wrap"}>
                    <button className={"rounded bg-gray-500 px-4 py-1 text-white text-sm hover:bg-gray-600"}>전체 선택</button>
                    <button className={"rounded bg-gray-500 px-4 py-1 text-white text-sm hover:bg-gray-600"}>선택 삭제</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(Customer)