import React from "react";
import Main from "./Main";
import * as Global from "../../constants/Global";
import Betting from "../betting/Betting";
import BettingSpecial from "../betting/BettingSpecial";
import UserDeposit from "../user/deposit/UserDeposit";
import UserWithdraw from "../user/withdraw/UserWithdraw";
import Customer from "../customer/Customer";
import BettingGuide from "../betting/BettingGuide";
import Notice from "../customer/Notice";
import MinigameBetting from "../minigame/MinigameBetting";
import CasinoMain from "../casino/CasinoMain";
import SlotMain from "../slot/SlotMain";
import MyBettingList from "../user/betting/MyBettingList";
import UserNotes from "../user/note/UserNotes";

const Contents = ({menuIdx, onMenu}) => {
    const renderContent = () => {
        switch (menuIdx) {
            case Global.MENU_BETTING: // 배팅
                return <Betting />
            case Global.MENU_BETTING_SPECIAL: // 스페셜
                return <BettingSpecial />
            case Global.MENU_CUSTOMER: // 고객센터
                return <Customer />
            case Global.MENU_BOARD: // 게시판
                return
            case Global.MENU_BETTING_HISTORY: //  배팅내역
                return <MyBettingList />
            case Global.MENU_BETTING_RULE: //  배팅규정
                return <BettingGuide />
            case Global.MENU_NOTICE: //  공지사항
                return <Notice />
            case Global.MENU_CHARGE: // 충전
                return <UserDeposit />
            case Global.MENU_WITHDRAW:  // 환전
                return <UserWithdraw />
            case Global.MENU_MINIGAME: // 미니게임
                return <MinigameBetting />
            case Global.MENU_CASINO:    // 카지노
                return <CasinoMain />
            case Global.MENU_SLOT:    // 슬롯
                return <SlotMain />
            case Global.MENU_NOTES:    // 쪽지함
                return <UserNotes />
            default:
                return <Main onMenu={onMenu} />
        }
    }

    return (
        <div className="h-full">
            {renderContent()}
        </div>
    )
}

export default Contents;