import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import * as Global from '../../../constants/Global';
import { EnvelopeIcon, LockOpenIcon, BanknotesIcon, ArrowPathRoundedSquareIcon, ShieldExclamationIcon } from "@heroicons/react/24/outline";
import {MENU_EXCHANGE_POINT} from "../../../constants/Global";

const UserLogout = ({ setLogin, onMenu }) => {
    const { user } = useSelector(state => state.account);
    const { siteConfig } = useSelector(state => state.siteConfig);
    const hasUnreadNotes = user?.unreadNoteCount > 0;

    const logout = useCallback(() => {
        localStorage.removeItem('token');
        setLogin(false);
        onMenu(0);
    }, [setLogin, onMenu]);

    const onPoint = useCallback(() => {
        if (hasUnreadNotes) {
            alert('읽지 않은 쪽지가 있습니다. 쪽지를 확인해주세요.');
            return;
        }
        onMenu(Global.MENU_EXCHANGE_POINT);
    }, [onMenu, hasUnreadNotes]);

    const onNotes = useCallback(() => {
        onMenu(Global.MENU_NOTES);
    }, [onMenu]);

    const handleMenuClick = useCallback((menuId) => {
        if (hasUnreadNotes) {
            alert('읽지 않은 쪽지가 있습니다. 쪽지를 확인해주세요.');
            return;
        }

        onMenu(menuId);
    }, [onMenu, hasUnreadNotes]);

    // unreadNoteCount가 있을 때 자동으로 쪽지함으로 이동
    useEffect(() => {
        if (hasUnreadNotes) {
            onNotes();
        }
    }, [hasUnreadNotes, onNotes]);

    return (
        <div className="flex flex-row justify-center w-full bg-white">
            <div className="w-full max-w-[1280px] flex flex-col md:flex-row items-start md:items-center justify-end p-2 px-2 gap-2 md:gap-3 border-b border-gray-200">
                <div className="flex flex-row items-center gap-3 w-full md:w-auto justify-between md:justify-start">
                    <span className="text-sm text-gray-700 whitespace-nowrap">{user.nickName}</span>
                    <button 
                        className="flex flex-row gap-1 items-center hover:text-blue-600 transition-colors" 
                        onClick={onNotes}
                    >
                        <EnvelopeIcon className="h-4 w-4 text-gray-500" />
                        <span className="text-sm text-gray-700">{user.unreadNoteCount}</span>
                    </button>
                </div>

                <div className="flex flex-row items-center gap-3 w-full md:w-auto justify-between md:justify-start">
                    <button className="flex flex-row gap-1 items-center" onClick={() => handleMenuClick(Global.MENU_CHARGE)}>
                        <span className="text-sm text-gray-700">보유금액</span>
                        <span className="text-sm text-blue-600">{numeral(user.userCash).format("0,0")} 원</span>
                    </button>
                    <button className="flex flex-row gap-1 items-center" onClick={() => handleMenuClick(Global.MENU_EXCHANGE_POINT)}>
                        <span className="text-sm text-gray-700">포인트</span>
                        <span className="text-sm text-blue-600">{numeral(user.userPoint).format("0,0")}p</span>
                    </button>
                </div>

                <div className="flex flex-row items-center gap-2 w-full md:w-auto justify-between md:justify-start">
                    <button className="flex flex-row gap-1 items-center" onClick={logout}>
                        <LockOpenIcon className="h-4 w-4 text-gray-500" />
                        <span className="text-sm text-gray-700">로그아웃</span>
                    </button>
                    <div className="flex flex-row gap-1 items-center">
                        <button className="flex flex-row gap-1 items-center px-2" onClick={() => handleMenuClick(Global.MENU_CHARGE)}>
                            <BanknotesIcon className="h-4 w-4 text-gray-600" />
                            <span className="text-sm text-gray-700">충전</span>
                        </button>
                        <button className="flex flex-row gap-1 items-center px-2 border-l border-gray-300" onClick={() => handleMenuClick(Global.MENU_WITHDRAW)}>
                            <ArrowPathRoundedSquareIcon className="h-4 w-4 text-gray-600" />
                            <span className="text-sm text-gray-700">환전</span>
                        </button>
                        <button className="flex flex-row gap-1 items-center px-2 border-l border-gray-300" onClick={() => handleMenuClick(Global.MENU_EXCHANGE_POINT)}>
                            <ShieldExclamationIcon className="h-4 w-4 text-gray-600" />
                            <span className="text-sm text-gray-700">포인트전환</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(UserLogout);