import axios from "axios";
import {apiHost} from "../constants/Global";

// export const host = "http://localhost:18080"
// export const host = "https://popobu.ngrok.app"
const proxy = "https://ziha-proxy-bef22eb1e8af.herokuapp.com/";

const isDebug = true

function post(request, body) {
    let http = proxy + request
    if (isDebug) {
        http = request
    }

    return axios.post(http, body);
}

function get(request, body) {
    let http = proxy + request
    if (isDebug) {
        http = request
    }

    return axios.get(http, {
        params: body,
    })
}

export function put(request, body) {
    let http = proxy + request
    if (isDebug) {
        http = request
    }

    return axios.put(http, body);
}

// 사이트 접속 기본 정보
export function getMainInfo(params) {
    const request = apiHost + "/api/v1/main"
    return post(request, params)
}

// export function getMatchList(match) {
//     const request = host + "/" + match + "/list?token=HJ4wIB6sTp52uULLYLDzBRs0VsbAlVXR";
//     return get(request);
// }

// export function getOddsList(match) {
//     const request = host + "/" + match + "/multi?token=HJ4wIB6sTp52uULLYLDzBRs0VsbAlVXR";
//     return get(request);
// }

export function getMatchList(match) {
    const request = apiHost + "/api/v1/sports/list/" + match
    return get(request)
}

export function getSpecialMatchList(match) {
    const request = apiHost + "/api/v1/sports/special/list/" + match
    return get(request)
}

export function login(info) {
    // const request = host2 + "/login/";
    const request = apiHost + "/api/v1/login/"
    return post(request, info);
}

export function getGUser(userId) {
    const request = apiHost + "/api/v1/user/" + userId;
    return get(request);
}

export function putGUser(gUser) {
    const request = apiHost + "/api/v1/user/";
    return put(request, gUser);
}

/**
 * 입금신청
 */
export function getDepositList(params) {
    const request = apiHost + "/api/v1/user/cash/deposit/request/list"
    return post(request, params)
}

export function putDeposit(deposit) {
    const request = apiHost + "/api/v1/user/cash/deposit";
    return put(request, deposit)
}

/**
 * 출금신청
 */
export function getWithdrawList(params) {
    const request = apiHost + "/api/v1/user/cash/withdraw/request/list"
    return post(request, params)
}

export function putWithdraw(withdraw) {
    const request = apiHost + "/api/v1/user/cash/withdraw";
    return put(request, withdraw)
}

/**
 * 경기목록 (now)
 */
export function getSportsNow() {
    const request = apiHost + "/api/v1/sports/now";
    return get(request)
}

/**
 * 상위 출금 리스트
 */
export function getWithdrawTopList(params) {
    const request = apiHost + "/api/v1/user/cash/top/withdraw/list";
    return post(request, params)
}

/**
 * 배팅
 */
export function putBetting(betting) {
    const request = apiHost + "/api/v1/betting/";
    return put(request, betting)
}

export function cancelBetting(betting) {
    const request = apiHost + "/api/v1/betting/cancel"
    return put(request, betting)
}

// 스포츠 베팅 이력
export function getUserBettingList(userId) {
    const request = apiHost + "/api/v1/betting/" + userId + "/list"
    return get(request)
}

export function getUserMinigameBettingList(params) {
    const request = apiHost + "/api/v1/betting/minigame/list"
    return post(request, params)
}

export function getUserCasinoBettingList(params) {
    const request = apiHost + "/api/v1/betting/casino/list"
    return post(request, params)
}

export function getUserSlotBettingList(params) {
    const request = apiHost + "/api/v1/betting/slot/list"
    return post(request, params)
}

// 미니게임 현재 회차정보
export function getMinigameRound() {
    const request = apiHost + "/api/v1/minigame/powerball/round"
    return get(request)
}

// 미니게임 베팅
export function putMinigameBetting(betting) {
    const request = apiHost + "/api/v1/betting/minigame"
    return put(request, betting)
}

/**
 * 포인트전환
 */
export function requestExchangePoint(exchange) {
    const request = apiHost + "/api/v1/user/point/exchange"
    return post(request, exchange)
}

export function getBoardList(boardId) {
    const request = apiHost + "/api/v1/board/" + boardId
    return get(request)
}

export function getSportsBaseList() {
    const request = apiHost + "/api/v1/sports/base/list";
    return get(request)
}

// 점검 여부
export function getSiteInspection(siteId) {
    const request = apiHost + "/api/v1/config/site/inspection/" + siteId
    return get(request)
}

// 벤더 목록
export function getMiracleVendors(params) {
    const request = apiHost + "/api/v1/minigame/miracle/vendors"
    return post(request, params)
}

// 게임 목록
export function getMiracleGames(params) {
    const request = apiHost + "/api/v1/minigame/miracle/games"
    return post(request, params)
}

// 게임 참여 요청
export function requestGameLaunch(params) {
    const request = apiHost + "/api/v1/minigame/miracle/games/launch"
    return post(request, params)
}

/**
 * 쪽지 관련 API
 */

// 사용자의 쪽지 목록 조회
export function getNoteList(params) {
    const request = apiHost + "/api/v1/user-notes/list";
    return get(request, {
        companyCode: params.companyCode,
        userId: params.userId,
        page: params.page || 0,
        size: params.size || 10
    });
}

// 쪽지 상세 조회
export function getNoteDetail(seq) {
    const request = apiHost + "/api/v1/user-notes/" + seq;
    return get(request);
}

// 쪽지 읽음 처리
export function updateNoteReadStatus(seq, userId) {
    const request = apiHost + "/api/v1/user-notes/" + seq + "/read";
    return axios.put(request, null, { params: { userId } });
}

// 쪽지 삭제 (논리적 삭제)
export function deleteNote(seq, userId) {
    const request = apiHost + "/api/v1/user-notes/" + seq;
    return axios.delete(request, { 
        params: { userId }
    });
}
