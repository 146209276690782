
export const matches = {
    "error": null,
    "data": [
        {
            "id": "all",
            "name": "전체"
        },
        {
            "id": "soccer",
            "name": "축구",
        },
        {
            "id": "baseball",
            "name": "야구",
        },
        {
            "id": "basketball",
            "name": "농구",
        },
        {
            "id": "volleyball",
            "name": "배구",
        },
        {
            "id": "icehockey",
            "name": "하키",
            "count": 0
        },
        {
            "id": "americanfootball",
            "name": "미식축구",
            "count": 0
        }
    ]
}