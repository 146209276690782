import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistStore, persistReducer } from 'redux-persist';
import { thunk } from 'redux-thunk';

import accountReducer from "../redux/actions/account"
import { websocketReducer } from './actions/websocket';
import messengerReducer from './actions/messenger';
import userNotesReducer from './reducers/userNotes';
import siteConfigReducer from './reducers/siteConfig';

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    whitelist: ['account'],
}

const initialState = {
    sidebarShow: true
}

const changeState = (state = initialState, {type, ...rest}) => {
    switch (type) {
        case 'set':
            return {...state, ...rest}
        default:
            return state
    }
}

const rootReducer = combineReducers({
    state: changeState,
    account: accountReducer,
    websocket: websocketReducer,
    messenger: messengerReducer,
    userNotes: userNotesReducer,
    siteConfig: siteConfigReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
    const persistor = persistStore(store);
    return {
        store, persistor
    };
};

export default configureStore;