export const minigames = {
    "error": null,
    "data": [
        {
            "id": "dhpowerball",
            "name": "동행파워볼",
            "url": "https://dhpowerball.net/rpowerball/live.php"
        },
        {
            "id": "dhpowerladder",
            "name": "동행파워사다리",
            "url": "https://dhpowerball.net/powerladder/live.php"
        }
    ]
}