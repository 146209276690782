import logo from "../../../assets/logo.png"
import { MegaphoneIcon, EnvelopeIcon } from "@heroicons/react/16/solid";
import { useSelector } from "react-redux";

const Marquee = ({ text }) => {
    return (
        <div className={"marquee flex-1"}>
            <div className={"marquee-content text-sm font-bold text-gray-700"}>
                {text}
            </div>
        </div>
    )
}

const Header = (props) => {
    const { user } = useSelector(state => state.account);
    const userNotes = useSelector(state => state.userNotes) || {};
    const unreadCount = userNotes.unreadCount || 0;
    const isLogin = !!user && Object.keys(user).length > 0;

    const handleMenuClick = (menuId) => {
        if (unreadCount > 0 && menuId !== 13) {
            alert('읽지 않은 쪽지가 있습니다. 쪽지를 확인해주세요.');
            return;
        }
        props.onMenu(menuId);
    };

    return (
        <div className={"flex flex-col items-center w-full bg-white"}>
            <div className={"w-full flex justify-center border-b border-gray-200"}>
                <div className={"w-full max-w-[1280px] max-h-[50px] flex flex-row items-center gap-4 p-2 md:p-4"}>
                    <img className={"w-[80px] cursor-pointer"} 
                         src={logo} 
                         alt={"로고"} 
                         onClick={() => handleMenuClick(0)} />
                    
                    <div className={"hidden md:flex flex-1 flex-row items-center gap-2"}>
                        <MegaphoneIcon className="h-4 w-4 text-gray-600" />
                        <Marquee text={"◆◆ 입금하시기전에는 항상 계좌문의 하시고 이용해주시기 바랍니다 ◆◆"} />
                    </div>
                </div>
            </div>

            <div className={"w-full flex justify-center bg-white border-b border-gray-200"}>
                <div className={"w-full max-w-[1280px] overflow-x-auto scrollbar-none"}>
                    <div className={"flex flex-row items-center justify-start md:justify-center min-w-max px-2 md:px-4"}>
                        <button className={"px-3 py-2.5 md:py-3"}>
                            <span className={"text-gray-700 text-sm font-bold hover:text-blue-600 whitespace-nowrap"} 
                                  onClick={() => handleMenuClick(1)}>조합배팅</span>
                        </button>
                        <button className={"px-3 py-2.5 md:py-3"}>
                            <span className={"text-gray-700 text-sm font-bold hover:text-blue-600 whitespace-nowrap"}
                                  onClick={() => handleMenuClick(7)}>스페셜</span>
                        </button>
                        <button className={"px-3 py-2.5 md:py-3"}>
                            <span className={"text-gray-700 text-sm font-bold hover:text-blue-600 whitespace-nowrap"}
                                  onClick={() => handleMenuClick(8)}>미니게임</span>
                        </button>
                        <button className={"px-3 py-2.5 md:py-3"}>
                            <span className={"text-gray-700 text-sm font-bold hover:text-blue-600 whitespace-nowrap"}
                                  onClick={() => handleMenuClick(9)}>카지노</span>
                        </button>
                        <button className={"px-3 py-2.5 md:py-3"}>
                            <span className={"text-gray-700 text-sm font-bold hover:text-blue-600 whitespace-nowrap"}
                                  onClick={() => handleMenuClick(10)}>슬롯</span>
                        </button>
                        <button className={"px-3 py-2.5 md:py-3"}>
                            <span className={"text-gray-700 text-sm font-bold hover:text-blue-600 whitespace-nowrap"} 
                                  onClick={() => handleMenuClick(2)}>고객센터</span>
                        </button>
                        <button className={"px-3 py-2.5 md:py-3"}>
                            <span className={"text-gray-700 text-sm font-bold hover:text-blue-600 whitespace-nowrap"} 
                                  onClick={() => handleMenuClick(4)}>배팅내역</span>
                        </button>
                        <button className={"px-3 py-2.5 md:py-3"}>
                            <span className={"text-gray-700 text-sm font-bold hover:text-blue-600 whitespace-nowrap"} 
                                  onClick={() => handleMenuClick(5)}>배팅규정</span>
                        </button>
                        <button className={"px-3 py-2.5 md:py-3"}>
                            <span className={"text-gray-700 text-sm font-bold hover:text-blue-600 whitespace-nowrap"} 
                                  onClick={() => handleMenuClick(6)}>공지사항</span>
                        </button>
                        {isLogin && (
                            <button className={"px-3 py-2.5 md:py-3 relative"}>
                                <span className={"text-gray-700 text-sm font-bold hover:text-blue-600 whitespace-nowrap flex items-center gap-1"} 
                                      onClick={() => handleMenuClick(13)}>
                                    쪽지함
                                    {unreadCount > 0 && (
                                        <>
                                            <EnvelopeIcon className="h-4 w-4 text-blue-500" />
                                            <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                                                {unreadCount}
                                            </span>
                                        </>
                                    )}
                                </span>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;