import {useEffect, useState} from "react";
import {minigames} from "../../assets/data/minigames";

const MinigameCategory = ({onSelectGame, onInitCart}) => {
    const [selectedGame, setSelectedGame] = useState(minigames.data[0]);

    useEffect(() => {
        // 초기 로딩시 첫번째 종목 선택
        onMinigame(minigames.data[0]);
    }, []);

    const onMinigame = (match) => {
        setSelectedGame(match);
        onInitCart();
        onSelectGame({
            id: match.id,
            name: match.name,
            url: match.url
        });
    }

    return (
        <div className="w-full">
            <div className="overflow-x-auto">
                <div className="flex flex-row bg-white rounded shadow-sm md:gap-2 gap-2 min-w-min">
                    {minigames.data.map((game, idx) => {
                        const isSelected = game.id === selectedGame.id;
                        return (
                            <button
                                key={String(idx)}
                                className={`flex-shrink-0 flex items-center gap-2 md:px-4 px-3 py-1 rounded-lg transition-colors whitespace-nowrap ${
                                    isSelected
                                        ? "bg-blue-500 text-white"
                                        : "text-gray-700 hover:bg-gray-50"
                                }`}
                                onClick={() => onMinigame(game)}
                            >
                                <span className="font-medium">{game.name}</span>
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default MinigameCategory;