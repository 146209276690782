import React, { useEffect, useState, useCallback, memo, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import * as api from '../../../api/api';
import { userNotesActions } from '../../../redux/actions/usernotes';
import { CModal } from '@coreui/react';
import UserNoteDetail from './UserNoteDetail';
import { EnvelopeIcon, TrashIcon, EyeIcon } from "@heroicons/react/24/outline";

// 날짜 포맷팅 함수를 컴포넌트 외부로 이동
const formatDateString = (dateString) => {
    if (!dateString) return '';
    
    try {
        const date = new Date(dateString.replace(' ', 'T'));
        return date.toLocaleString('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    } catch (e) {
        return dateString;
    }
};

// 로딩 애니메이션 컴포넌트를 memo로 최적화
const LoadingSpinner = memo(() => (
    <div className="flex flex-col items-center justify-center p-10 bg-white rounded-lg shadow">
        <div className="w-12 h-12 border-4 border-blue-200 border-t-blue-500 rounded-full animate-spin mb-4"></div>
        <p className="text-gray-600">쪽지를 불러오는 중입니다...</p>
    </div>
));

// 쪽지 항목 컴포넌트를 분리하여 성능 최적화
const NoteItem = memo(({ note, onView, onDelete, formatDate }) => {
    // 쪽지 항목 클릭 시 조회 처리
    const handleClick = useCallback(() => {
        onView(note);
    }, [note, onView]);
    
    // 삭제 버튼 클릭 시 처리
    const handleDelete = useCallback((e) => {
        e.stopPropagation();
        onDelete(note.seq, e);
    }, [note.seq, onDelete]);
    
    return (
        <div 
            className={`grid grid-cols-12 text-sm items-center hover:bg-gray-50 cursor-pointer ${note.readYn === 0 ? 'bg-blue-50' : ''}`}
            onClick={handleClick}
        >
            <div className="col-span-1 py-2 px-3 text-center">
                {note.readYn === 0 && (
                    <EnvelopeIcon className="h-4 w-4 text-blue-500 inline" />
                )}
            </div>
            <div className={`col-span-6 py-2 px-3 ${note.readYn === 0 ? 'font-bold' : ''}`}>
                {note.subject}
                {note.readYn === 0 && (
                    <span className="ml-2 px-1.5 py-0.5 text-xs bg-blue-500 text-white rounded-full">
                        N
                    </span>
                )}
            </div>
            <div className="col-span-3 py-2 px-3 text-center text-gray-500">
                {formatDate(note.instTime)}
            </div>
            <div className="col-span-2 py-2 px-3 text-center flex justify-center space-x-2" onClick={e => e.stopPropagation()}>
                <button 
                    className="p-1 text-gray-500 hover:text-gray-700 rounded"
                    onClick={handleClick}
                    title="상세보기"
                >
                    <EyeIcon className="h-4 w-4" />
                </button>
                <button 
                    className="p-1 text-red-500 hover:text-red-700 rounded"
                    onClick={handleDelete}
                    title="삭제"
                >
                    <TrashIcon className="h-4 w-4" />
                </button>
            </div>
        </div>
    );
});

// 컴포넌트를 memo로 감싸서 불필요한 리렌더링 방지
const UserNotes = memo((props) => {
    const { user } = useSelector((props) => props.account);
    const { unreadCount } = useSelector((props) => props.userNotes);
    const [notes, setNotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [initialLoaded, setInitialLoaded] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [selectedNote, setSelectedNote] = useState(null);
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    
    // useRef를 사용하여 최신 상태를 참조하되 함수 재생성 방지
    const loadingRef = useRef(loading);
    const unreadCountRef = useRef(unreadCount);
    const notesRef = useRef(notes);
    
    // 값이 변경될 때 ref도 업데이트
    useEffect(() => {
        loadingRef.current = loading;
    }, [loading]);
    
    useEffect(() => {
        unreadCountRef.current = unreadCount;
    }, [unreadCount]);
    
    useEffect(() => {
        notesRef.current = notes;
    }, [notes]);
    
    // 쪽지 목록 조회 함수
    const fetchNotes = useCallback(async (pageNum = 0) => {
        if (loadingRef.current && pageNum > 0) return;
        
        setLoading(true);
        try {
            const params = {
                companyCode: user.companyCode,
                userId: user.userId,
                page: pageNum,
                size: 10
            };
            
            const response = await api.getNoteList(params);
            const { data, status } = response;
            
            if (status === 200 && data && data.data) {
                const newNotes = data.data.notes || [];
                
                if (pageNum === 0) {
                    setNotes(newNotes);
                } else {
                    setNotes(prev => [...prev, ...newNotes]);
                }
                
                const totalPages = data.data.totalPages || 1;
                setHasMore(pageNum < totalPages - 1);
                
                const apiUnreadCount = data.data.unreadCount || 0;
                if (apiUnreadCount !== unreadCountRef.current) {
                    props.setUnreadCount(apiUnreadCount);
                }
            }
        } catch (error) {
            console.error('쪽지 목록 조회 오류:', error);
        } finally {
            setLoading(false);
            if (!initialLoaded && pageNum === 0) {
                setInitialLoaded(true);
            }
        }
    }, [user, props.setUnreadCount]);
    
    // 컴포넌트 마운트 시 또는 유저 변경 시에만 한 번 쪽지 목록 조회
    const isInitialMount = useRef(true);
    
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            if (user && user.userId) {
                fetchNotes(0);
            } else {
                setLoading(false);
                setInitialLoaded(true);
            }
        }
    }, [user, fetchNotes]);
    
    // 페이지 변경 시에만 추가 데이터 로드
    useEffect(() => {
        if (page > 0 && user && user.userId) {
            fetchNotes(page);
        }
    }, [page, fetchNotes]);
    
    // 모든 읽지 않은 쪽지 한 번에 읽음 처리
    const markAllAsRead = useCallback(async () => {
        if (!user || !user.userId || unreadCountRef.current === 0) return;
        
        if (!window.confirm('모든 쪽지를 읽음 상태로 변경하시겠습니까?')) return;
        
        setLoading(true);
        try {
            const unreadNoteIds = notesRef.current
                .filter(note => note.readYn === 0)
                .map(note => note.seq);
                
            if (unreadNoteIds.length === 0) {
                return;
            }
            
            for (const noteId of unreadNoteIds) {
                await api.updateNoteReadStatus(noteId, user.userId);
            }
            
            setNotes(prevNotes => 
                prevNotes.map(note => note.readYn === 0 ? { ...note, readYn: 1 } : note)
            );
            
            props.setUnreadCount(0);
            window.alert('모든 쪽지가 읽음 상태로 변경되었습니다.');
        } catch (error) {
            console.error('쪽지 읽음 처리 중 오류 발생:', error);
            window.alert('쪽지 읽음 처리 중 오류가 발생했습니다.');
        } finally {
            setLoading(false);
        }
    }, [user, props.setUnreadCount]);
    
    // 쪽지 상세 조회
    const handleViewNote = useCallback((note) => {
        setSelectedNote(note);
        setDetailModalOpen(true);
    }, []);
    
    // 쪽지 삭제
    const handleDeleteNote = useCallback(async (seq, e) => {
        e?.stopPropagation();
        
        if (!window.confirm('쪽지를 삭제하시겠습니까?')) return;
        
        try {
            const response = await api.deleteNote(seq, user.userId);
            if (response.status === 200) {
                const deletedNote = notesRef.current.find(note => note.seq === seq);
                const wasUnread = deletedNote && deletedNote.readYn === 0;
                
                setNotes(prev => prev.filter(note => note.seq !== seq));
                window.alert('쪽지가 삭제되었습니다.');
                
                if (wasUnread && unreadCountRef.current > 0) {
                    props.setUnreadCount(unreadCountRef.current - 1);
                }
            }
        } catch (error) {
            console.error('쪽지 삭제 오류:', error);
            window.alert('쪽지 삭제 중 오류가 발생했습니다.');
        }
    }, [user, props.setUnreadCount]);
    
    // 더 불러오기
    const loadMore = useCallback(() => {
        if (hasMore && !loading) {
            setPage(prev => prev + 1);
        }
    }, [hasMore, loading]);
    
    // 상세 모달 닫기 후 읽음 상태 업데이트
    const closeDetailModal = useCallback(() => {
        setDetailModalOpen(false);
        setTimeout(() => {
            setSelectedNote(null);
        }, 0);
    }, []);
    
    // 쪽지 읽음 상태 변경 처리 콜백
    const handleNoteRead = useCallback((noteSeq) => {
        setNotes(prev => prev.map(note => 
            note.seq === noteSeq ? { ...note, readYn: 1 } : note
        ));
    }, []);
    
    // 읽지 않은 쪽지 수 계산
    const unreadNotesCount = notes.filter(note => note.readYn === 0).length;
    
    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-800">쪽지함</h2>
                
                <div className="flex items-center gap-2">
                    {unreadNotesCount > 0 && (
                        <button 
                            className="px-3 py-1.5 text-sm bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                            onClick={markAllAsRead}
                            disabled={loading}
                        >
                            모두 읽음 처리
                        </button>
                    )}
                </div>
            </div>
            
            {loading && !initialLoaded ? (
                <LoadingSpinner />
            ) : notes.length === 0 ? (
                <div className="text-center p-6 bg-white rounded-lg shadow">
                    <p className="text-gray-500">받은 쪽지가 없습니다.</p>
                </div>
            ) : (
                <div className="bg-white rounded-lg shadow overflow-hidden">
                    {unreadNotesCount > 0 && (
                        <div className="bg-blue-50 p-2 text-sm text-blue-700 text-center border-b border-blue-100">
                            읽지 않은 쪽지가 {unreadNotesCount}개 있습니다.
                        </div>
                    )}
                
                    <div className="grid grid-cols-12 text-sm font-medium text-gray-600 bg-gray-100 border-b border-gray-200">
                        <div className="col-span-1 p-3 text-center">#</div>
                        <div className="col-span-6 p-3">제목</div>
                        <div className="col-span-3 p-3 text-center">수신일시</div>
                        <div className="col-span-2 p-3 text-center">관리</div>
                    </div>
                    
                    <div className="divide-y divide-gray-200">
                        {notes.map((note) => (
                            <NoteItem 
                                key={note.seq}
                                note={note}
                                onView={handleViewNote}
                                onDelete={handleDeleteNote}
                                formatDate={formatDateString}
                            />
                        ))}
                    </div>
                    
                    {hasMore && (
                        <div className="p-3 text-center border-t border-gray-200">
                            {loading && initialLoaded ? (
                                <div className="w-8 h-8 border-4 border-blue-200 border-t-blue-500 rounded-full animate-spin mx-auto"></div>
                            ) : (
                                <button 
                                    className="px-4 py-1.5 text-sm bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                                    onClick={loadMore}
                                    disabled={loading}
                                >
                                    더 보기
                                </button>
                            )}
                        </div>
                    )}
                </div>
            )}
            
            <CModal 
                backdrop={"static"}
                alignment={"center"}
                visible={detailModalOpen}
            >
                {selectedNote && (
                    <UserNoteDetail 
                        note={selectedNote}
                        onClose={closeDetailModal}
                        onRead={handleNoteRead}
                    />
                )}
            </CModal>
        </div>
    );
});

// mapDispatchToProps를 객체 형태로 사용하여 불필요한 함수 생성 방지
const mapDispatchToProps = {
    setNotes: userNotesActions.setNotes,
    setUnreadCount: userNotesActions.setUnreadCount
};

export default connect(
    state => ({ userNotes: state.userNotes }), 
    mapDispatchToProps
)(UserNotes); 