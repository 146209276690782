const WidgetCustomerService = ({ messengerList }) => {
    const handleMessengerClick = (messenger) => {
        if (messenger.messengerUrl) {
            window.open(messenger.messengerUrl, '_blank');
        }
    };

    return (
        <div className="bg-white rounded-lg shadow p-4">
            <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-bold text-gray-800">고객센터</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                {messengerList?.map((messenger) => (
                    messenger.useYn === 1 && (
                        <button
                            key={messenger.seq}
                            onClick={() => handleMessengerClick(messenger)}
                            className="flex items-center justify-center gap-2 p-3 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
                        >
                            <img 
                                src={`/images/${messenger.messengerType}.webp`}
                                alt={messenger.messengerNm}
                                className="w-6 h-6"
                            />
                            <span className="text-sm font-medium text-gray-700">
                                {messenger.messengerNm}
                            </span>
                        </button>
                    )
                ))}
            </div>
        </div>
    );
};

export default WidgetCustomerService; 