import {useEffect, useState, useCallback, useRef} from "react";
import RollingBanner from "./widget/RollingBanner";
import MenuButton from "./widget/MenuButton";
import WidgetGameSchedule from "./widget/WidgetGameSchedule";
import WidgetWeekWithdraw from "./widget/WidgetWeekWithdraw";
import WidgetBoard from "./widget/WidgetBoard";
import {accountActions} from "../../redux/actions/account";
import {connect} from "react-redux";
import * as Global from "../../constants/Global";
import * as api from "../../api/api";
import Loading from "../../component/Loading";
import {getBoardList} from "../../api/api";
import WidgetCustomerService from "./widget/WidgetCustomerService";
import {useSelector, useDispatch} from "react-redux";
import React, {memo} from "react";
import {setSiteConfig} from "../../redux/reducers/siteConfig";
import {useWebSocket} from "../../hooks/useWebSocket";
import {MENU_EXCHANGE_POINT} from "../../constants/Global";

const Main = memo(({onMenu}) => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.account);
    const {siteConfig} = useSelector(state => state.siteConfig);
    const {subscribe, unsubscribe, isSubscribed} = useWebSocket();
    const [loading, setLoading] = useState(false)
    const [nowSports, setNowSports] = useState([])
    const [noticeList, setNoticeList] = useState([])
    const [withdrawTopList, setWithdrawTopList] = useState([])
    const [messengerList, setMessengetList] = useState([])
    const [config, setConfig] = useState({})
    const isInitialMount = useRef(true)
    
    const params = useRef({
        companyCode: user?.companyCode,
        userId: user?.userId
    });

    const handleMenuClick = useCallback((menuId) => {
        if (menuId === MENU_EXCHANGE_POINT) {
            // 보유 포인트가 최소 교환 가능 포인트 이상인지
            if (user.userPoint < config.minExchangePoint) {
                alert("보유 포인트가 부족합니다.")
                return
            }
        }

        onMenu(menuId);
    }, [onMenu]);

    const getMainInfo = useCallback(() => {
        api.getMainInfo(params.current).then(result => {
            const {status, statusText, data} = result
            if (status === 200) {
                // siteConfig 데이터를 Redux에 저장
                if (data.siteConfig) {
                    dispatch(setSiteConfig(data.siteConfig));
                    setConfig(data.siteConfig)
                }
                // 메신저정보
                setMessengetList(data.messengerList)
            }
        })
    }, [dispatch])

    const getNowSports = useCallback(() => {
        setLoading(true)
        api.getSportsNow().then(result => {
            const {status, statusText, data} = result;
            if (status === 200) {
                setNowSports(data)
            }
        })
            .catch(ex => {
                console.error(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const getWithdrawTopList = useCallback(() => {
        const params = {
            companyCode: "0000"
        }

        api.getWithdrawTopList(params).then(result => {
            const {status, statusText, data} = result;
            if (status === 200) {
                setWithdrawTopList(data)
            }
        })
    }, [])

    const getBoardListData = useCallback(() => {
        api.getBoardList(1).then(result => {
            const {status, statusText, data} = result;
            if (status === 200) {
                setNoticeList(data)
            }
        })
            .catch(ex => {
                console.error(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            if (user?.userId) {
                getMainInfo();
                getNowSports();
                getBoardListData();
                getWithdrawTopList();

                // WebSocket 구독
                if (!isSubscribed('all')) {
                    subscribe('all');
                }
            }
        }

        return () => {
            if (user?.userId) {
                unsubscribe('all');
            }
        };
    }, [getMainInfo, getNowSports, getBoardListData, getWithdrawTopList, user, subscribe, unsubscribe, dispatch]);

    return (
        <div className={"flex flex-col gap-4 p-3 max-w-[1280px] mx-auto"}>
            {loading ? <Loading /> : null}
            
            {/* 상단 영역 */}
            <div className={"flex flex-col md:flex-row gap-4"}>
                {/* RollingBanner */}
                <div className={"w-full md:w-1/2 md:h-auto"}>
                    <RollingBanner className={"w-full h-full"} />
                </div>
                
                {/* MenuButton 그리드 */}
                <div className={"w-full md:w-1/2"}>
                    <div className={"grid grid-cols-3 gap-2 md:gap-4 h-full"}>
                        <MenuButton 
                            id={Global.MENU_CHARGE} 
                            title={"보유머니 충전"} 
                            desc={"MONEY CHANGE"} 
                            onClick={handleMenuClick} 
                        />
                        <MenuButton 
                            id={Global.MENU_WITHDRAW} 
                            title={"보유머니 환전"} 
                            desc={"MONEY EXCHANGE"} 
                            onClick={handleMenuClick} 
                        />
                        <MenuButton 
                            id={Global.MENU_EXCHANGE_POINT} 
                            title={"포인트 전환"} 
                            desc={"POINT EXCHANGE"} 
                            onClick={handleMenuClick} 
                        />
                        <MenuButton 
                            id={Global.MENU_BETTING_RULE} 
                            title={"게임가이드"} 
                            desc={"GAME GUIDE"} 
                            onClick={handleMenuClick} 
                        />
                        <MenuButton 
                            id={Global.MENU_ATTENDANCE} 
                            title={"출석부"} 
                            desc={"ATTENDANCE"} 
                            onClick={handleMenuClick} 
                        />
                        <MenuButton 
                            id={Global.MENU_BETTING_HISTORY} 
                            title={"경기결과"} 
                            desc={"GAME RESULT"} 
                            onClick={handleMenuClick} 
                        />
                    </div>
                </div>
            </div>

            {/* 위젯 영역 */}
            <div className={"flex flex-col md:flex-row gap-4"}>
                <div className={"w-full md:w-1/2"}>
                    <WidgetGameSchedule list={nowSports} />
                </div>
                <div className={"w-full md:w-1/2"}>
                    <WidgetWeekWithdraw list={withdrawTopList} />
                </div>
            </div>
            <div className={"flex flex-col md:flex-row gap-4"}>
                <div className={"w-full"}>
                    <WidgetBoard list={noticeList} />
                </div>
            </div>

            {/* 고객센터 위젯 추가 */}
            <div className={"flex flex-col md:flex-row gap-4"}>
                <div className={"w-full"}>
                    <WidgetCustomerService messengerList={messengerList} />
                </div>
            </div>
        </div>
    )
})

// 컴포넌트 외부로 mapStateToProps와 mapDispatchToProps를 이동
const mapStateToProps = (state) => ({
    account: null
})

const mapDispatchToProps = {
    setAccount: accountActions.setAccount
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)