import Contents from "./Contents";
import Right from "./Right";
import {useState, useEffect} from "react";
import Loading from "../../component/Loading";
import {accountActions} from "../../redux/actions/account";
import {connect, useSelector} from "react-redux";
import * as api from "../../api/api";
import dayjs from "dayjs";
import {calcOdds} from "../biz/Betting";
import { XMarkIcon } from '@heroicons/react/24/outline';
import {useWebSocket} from "../../hooks/useWebSocket";
import SpecialMatch from "./SpecialMatch";

const BettingSpecial = (props) => {
    const {user} = useSelector((props) => props.account);
    const { connected, oddsUpdates, previousOdds } = useSelector((state) => state.websocket);
    const { subscribe, unsubscribe } = useWebSocket(false);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [isMobileCartOpen, setIsMobileCartOpen] = useState(false);
    const [selectedSport, setSelectedSport] = useState({
        id: 'all',
        name: '전체'
    });
    const [myInfo, setMyInfo] = useState({
        myCash: 0, myPoint: 100000
    });
    const [bettingList, setBettingList] = useState([]);
    const [bettingAmount, setBettingAmount] = useState(0);
    const [matchList, setMatchList] = useState([]);

    let currentPer = 1;

    // 초기 데이터 로드
    useEffect(() => {
        let mounted = true;

        const fetchInitialData = async () => {
            if (mounted) {
                await getMatchList('all');
            }
        };

        fetchInitialData();

        return () => {
            mounted = false;
        };
    }, []);

    // 웹소켓 구독 관리
    useEffect(() => {
        let mounted = true;

        const subscribeToOdds = async () => {
            if (!connected || !mounted) return;

            try {
                await subscribe('all');
            } catch (error) {
                console.error('Failed to subscribe:', error);
            }
        };

        subscribeToOdds();

        return () => {
            mounted = false;
            unsubscribe('all');
        };
    }, [connected, subscribe, unsubscribe]);

    // 배팅 목록이 있을 때 모바일 카트 자동 표시
    useEffect(() => {
        if (bettingList.length > 0) {
            setIsMobileCartOpen(true);
        } else {
            setIsMobileCartOpen(false);
        }
    }, [bettingList]);

    // useEffect 수정
    useEffect(() => {
        if (isMobileCartOpen) {
            // 모바일 카트가 열릴 때 body 스크롤 방지
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
        } else {
            // 모바일 카트가 닫힐 때 body 스크롤 복원
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.width = '';
        }

        return () => {
            // cleanup function
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.width = '';
        };
    }, [isMobileCartOpen]);

    // 배당 업데이트 처리
    useEffect(() => {
        if (!matchList.length || !oddsUpdates.size) return;

        const updatedMatches = matchList.map(match => {
            const newOdds = oddsUpdates.get(match.id);
            const prevOdds = previousOdds.get(match.id);

            if (newOdds) {
                return {
                    ...match,
                    odds: newOdds,
                    oddsChanges: calculateOddsChanges(newOdds, prevOdds)
                };
            }
            return match;
        });

        setMatchList(updatedMatches);
    }, [oddsUpdates, previousOdds, matchList]);

    // 배당 변경 계산 함수
    const calculateOddsChanges = (newOdds, prevOdds) => {
        if (!prevOdds) return null;

        return {
            home: newOdds.home - prevOdds.home,
            draw: newOdds.draw - prevOdds.draw,
            away: newOdds.away - prevOdds.away,
            timestamp: Date.now()
        };
    };

    const getMatchList = (matchId) => {
        setLoading(true);
        api.getSpecialMatchList(matchId).then(res => {
            const {data, status} = res;
            if (status === 200) {
                setMatchList(data);
            }
            else {
                window.alert("API 서버 통신 에러");
            }
        })
            .catch(ex => {
                window.alert(ex.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const onSelectLeague = (sport) => {
        setSelectedSport(sport);
    }

    const onInit = () => {
        setBettingList([])
        setBettingAmount(0)
    }

    const onBettingCart = (betting) => {
        // 중복 체크 - id 와 market_id 가 일치하는 경기는 중복될 수 없다
        const isExistData = bettingList.filter(item => item.id === betting.id && item.market_id === betting.market_id)
        if (isExistData.length > 0) {
            window.alert("배팅을 진행할 수 없습니다.")
            return
        }

        setBettingList([
            ...bettingList, betting
        ])
    }

    const onBettingAmount = (amount) => {
        setBettingAmount(amount)
    }

    const onBettingRowDelete = (idx) => {
        // 현재 목록에서 해당 idx를 제거한다
        if (bettingList.length > 0) {
            const newList = bettingList.filter((_, index) => index !== idx);
            // 상태를 업데이트
            setBettingList(newList)
        }
    }

    const onBetting = () => {
        if (bettingList.length === 0) {
            window.alert("선택된 배팅내역이 없습니다.")
            return;
        }

        // 최대 100배까지만 배팅이 가능하다
        const maxPer = 100;

        // 현재 배당율 계산
        currentPer = bettingList.reduce((acc, curr) => acc * curr.odd_value, 1)

        if (currentPer > maxPer) {
            window.alert("최대 100배 까지 배팅이 가능합니다.")
            return;
        }

        if (bettingAmount <= 0) {
            window.alert("배팅 금액을 입력해주세요.")
            return;
        }

        // 베팅 이력중 경기 시간이 지난 경기는 배팅 불가능하다
        const isPast = bettingList.some(item => {
            const match = matchList.find(match => match.id === item.id);
            return match && dayjs(match.time).isBefore(dayjs());
        });

        if (isPast) {
            window.alert("경기 시간이 지난 경기는 배팅 불가능합니다.")
            return;
        }

        if (window.confirm("위의 내역으로 배팅하시겠어요?")) {
            setLoading(true)
            betting()
        }
    }

    const betting = () => {
        // betting list 조립
        const list = []
        bettingList.map(item => {
            const match = {
                matchId: item.id,
                marketId: item.market_id,
                marketName: item.market_name,
                oddsName: item.odd_name,
                oddsBase: item.odds_base,
                oddsValue: item.odd_value,
                instUser: user.userId
            }
            list.push(match)
        })

        // betting header 조립
        const params = {
            companyCode: user.companyCode,
            bettingDt: dayjs().format('YYYYMMDD'),
            bettingUserId: user.userId,
            bettingCnt: bettingList.length,
            bettingOdds: calcOdds(bettingList),
            bettingAmount: bettingAmount,
            instUser: user.userId,
            detailList: list
        }

        api.putBetting(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                window.alert("배팅 처리가 완료되었습니다.")
                onInit()
                // login()
            }
            else {
                window.alert(statusText)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const login = () => {
        const {userId, password} = user;
        const params = {
            userId: userId,
            password: password
        }

        setLoading(true);
        api.login(params).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                props.setAccount(data)
            }
            else {
                window.alert(statusText)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <div className="flex flex-col h-[calc(100vh-144px)] bg-gray-50">
            {loading ? <Loading /> : null}

            {/* 상단 스포츠 선택 영역 - 고정 */}
            <div className="flex-shrink-0 px-2 py-2 bg-white border-b border-gray-200">
                <div className="max-w-[1280px] mx-auto">
                    <SpecialMatch onSelectLeague={onSelectLeague} />
                </div>
            </div>

            {/* 메인 컨텐츠 영역 */}
            <div className="flex-1 w-full overflow-hidden">
                <div className="max-w-[1280px] mx-auto relative h-full">
                    {/* 컨텐츠 영역 - 스크롤 가능 */}
                    <div className="md:mr-[300px] h-full">
                        <div className="h-full overflow-y-auto">
                            <div className="bg-white shadow-sm rounded-lg my-2">
                                <Contents
                                    data={matchList}
                                    props={selectedSport}
                                    title={title}
                                    onCart={onBettingCart}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 데스크톱 Right 컴포넌트 - 고정 */}
                    <div className="hidden md:block absolute top-0 right-0 w-[280px] h-full">
                        <div className="sticky top-2">
                            <Right
                                init={onInit}
                                info={myInfo}
                                onBetting={onBetting}
                                onBettingRowDelete={onBettingRowDelete}
                                bettingList={bettingList}
                                onBettingAmount={onBettingAmount}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* 모바일 배팅 카트 버튼 */}
            {bettingList.length > 0 && (
                <div className="md:hidden fixed bottom-4 left-0 right-0 px-4">
                    <button
                        className="w-full py-3 bg-blue-500 text-white rounded-lg shadow-lg flex items-center justify-center gap-2"
                        onClick={() => setIsMobileCartOpen(true)}
                    >
                        <span className="font-bold">배팅카트</span>
                        <span className="bg-blue-600 px-2 py-1 rounded-full text-sm">
                            {bettingList.length}
                        </span>
                    </button>
                </div>
            )}

            {/* 모바일 슬라이드 업 배팅 카트 */}
            <div
                className={`
                    md:hidden fixed inset-0 z-50 
                    transition-opacity duration-300 ease-in-out
                    ${isMobileCartOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}
                `}
            >
                <div
                    className={`
                        absolute inset-0 bg-black 
                        transition-opacity duration-300 ease-in-out
                        ${isMobileCartOpen ? 'opacity-50' : 'opacity-0'}
                    `}
                    onClick={() => setIsMobileCartOpen(false)}
                />
                <div
                    className={`
                        fixed bottom-0 left-0 right-0 
                        bg-white rounded-t-2xl 
                        transform transition-all duration-300 ease-in-out
                        max-h-[90vh] overflow-y-auto
                        ${isMobileCartOpen ? 'translate-y-0' : 'translate-y-full'}
                    `}
                >
                    {/* 헤더 */}
                    <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200">
                        <div className="flex items-center gap-2">
                            <span className="font-bold text-gray-800 text-lg">배팅카트</span>
                            <span className="bg-blue-500 text-white px-2.5 py-1 rounded-full text-sm font-medium">
                                {bettingList.length}
                            </span>
                        </div>
                        <button
                            onClick={() => setIsMobileCartOpen(false)}
                            className="p-1.5 rounded-full hover:bg-gray-100 active:bg-gray-200"
                        >
                            <XMarkIcon className="w-6 h-6 text-gray-500" />
                        </button>
                    </div>

                    {/* 모바일용 Right 컴포넌트 */}
                    <div className="flex-1 overflow-y-auto">
                        <Right
                            init={onInit}
                            info={myInfo}
                            onBetting={onBetting}
                            onBettingRowDelete={onBettingRowDelete}
                            bettingList={bettingList}
                            onBettingAmount={onBettingAmount}
                            isMobile={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BettingSpecial)