import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notes: [],
    unreadCount: 0,
    loading: false,
    error: null
};

const userNotesSlice = createSlice({
    name: 'userNotes',
    initialState,
    reducers: {
        setNotes: (state, action) => {
            state.notes = action.payload;
            state.unreadCount = action.payload.filter(note => !note.readYn).length;
        },
        setUnreadCount: (state, action) => {
            state.unreadCount = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        updateNoteReadStatus: (state, action) => {
            const { seq } = action.payload;
            const note = state.notes.find(n => n.seq === seq);
            if (note) {
                note.readYn = true;
                state.unreadCount = Math.max(0, state.unreadCount - 1);
            }
        },
        deleteNote: (state, action) => {
            const { seq } = action.payload;
            const note = state.notes.find(n => n.seq === seq);
            if (note && !note.readYn) {
                state.unreadCount = Math.max(0, state.unreadCount - 1);
            }
            state.notes = state.notes.filter(n => n.seq !== seq);
        }
    }
});

export const {
    setNotes,
    setUnreadCount,
    setLoading,
    setError,
    updateNoteReadStatus,
    deleteNote
} = userNotesSlice.actions;

export default userNotesSlice.reducer; 