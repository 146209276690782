import Header from "./header/Header";
import Contents from "./Contents";
import UserLogout from "./user/UserLogout";
import UserLogin from "./user/UserLogin";
import Main from "./Main";
import {useEffect, useState, useCallback, memo} from "react";
import {accountActions} from "../../redux/actions/account";
import {connect, useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import * as api from "../../api/api";
import PointExchange from "./modal/PointExchange";
import {CModal} from "@coreui/react";
import * as Global from "../../constants/Global";
import {useWebSocket} from '../../hooks/useWebSocket';
import Loading from "../../component/Loading";

const Frame = (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.account);
    const navigate = useNavigate();
    const [menuIdx, setMenuIdx] = useState(Global.MENU_HOME);
    const [pointExchangeOpen, setPointExchangeOpen] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(true);
    const {subscribe, unsubscribe, isSubscribed} = useWebSocket();
    
    const isLogin = !!user && Object.keys(user).length > 0;

    // 새로고침 시 사용자 정보 갱신
    useEffect(() => {
        const refreshUserInfo = async () => {
            const storedUserIdJson = localStorage.getItem('userId');
            const storedPasswordJson = localStorage.getItem('password');
            
            if (storedUserIdJson && storedPasswordJson) {
                try {
                    const storedUserIdData = JSON.parse(storedUserIdJson);
                    const storedPasswordData = JSON.parse(storedPasswordJson);
                    const storedUserId = storedUserIdData.value;
                    const storedPassword = storedPasswordData.value;
                    
                    console.log('[Frame] 새로고침/재진입: 사용자 정보 갱신 시작', { 
                        storedUserId,
                        hasPassword: !!storedPassword 
                    });
                    
                    const response = await api.login({
                        userId: storedUserId,
                        password: storedPassword,
                        companyCode: Global.companyCode
                    });
                    
                    if (response.status === 200 && response.data) {
                        const userData = response.data;
                        if (userData.useYn === 1 && userData.blockYn !== 1) {
                            console.log('[Frame] 사용자 정보 갱신 성공', { 
                                userId: userData.userId,
                                isSubscribed: isSubscribed('all')
                            });
                            
                            // 사용자 정보 갱신
                            dispatch(accountActions.setAccount(userData));
                            
                            // 웹소켓 구독
                            if (!isSubscribed('all')) {
                                console.log('[Frame] 웹소켓 구독 시도', { userId: userData.userId });
                                await subscribe('all', userData.userId);
                                console.log('[Frame] 웹소켓 구독 완료', { userId: userData.userId });
                            } else {
                                console.log('[Frame] 이미 구독 중이므로 구독 요청 스킵', { userId: userData.userId });
                            }
                        } else {
                            console.log('[Frame] 계정 비활성화로 인한 로그아웃', { 
                                userId: userData.userId,
                                useYn: userData.useYn,
                                blockYn: userData.blockYn
                            });
                            // 계정이 비활성화된 경우 로그아웃 처리
                            dispatch(accountActions.setAccount({}));
                            localStorage.removeItem('userId');
                            localStorage.removeItem('password');
                        }
                    }
                } catch (error) {
                    console.error('[Frame] 사용자 정보 갱신 실패:', error);
                    // 에러 발생 시 로그아웃 처리
                    dispatch(accountActions.setAccount({}));
                    localStorage.removeItem('userId');
                    localStorage.removeItem('password');
                }
            } else {
                console.log('[Frame] 새로고침/재진입: 저장된 사용자 정보 없음', {
                    hasUserId: !!storedUserIdJson,
                    hasPassword: !!storedPasswordJson
                });
            }
            setIsRefreshing(false);
        };

        refreshUserInfo();
    }, [dispatch]);

    const onMenu = useCallback((idx) => {
        if (idx !== Global.MENU_HOME && !isLogin) {
            window.alert("로그인이 필요한 서비스입니다.");
            return;
        }
        if (idx === Global.MENU_EXCHANGE_POINT) {
            setPointExchangeOpen(true);
            return;
        }
        setMenuIdx(idx);
    }, [isLogin]);

    useEffect(() => {
        if (!isLogin && menuIdx !== Global.MENU_HOME) {
            setMenuIdx(Global.MENU_HOME);
        }
    }, [isLogin, menuIdx]);

    return (
        <div className="flex flex-col h-screen bg-gray-50">
            {isRefreshing && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <Loading />
                </div>
            )}
            
            {/* 헤더 영역 - 고정 */}
            <div className="flex-none w-full bg-white relative z-10">
                <Header onMenu={onMenu} />
                {isLogin ? (
                    <UserLogout setLogin={props.setAccount} onMenu={onMenu} />
                ) : (
                    <UserLogin setLogin={props.setAccount} onMenu={onMenu} />
                )}
            </div>

            {/* 컨텐츠 영역 - 스크롤 가능 */}
            <div className="flex-1 min-h-0">
                <div className="h-full overflow-y-auto">
                    <div className="flex justify-center">
                        <div className="w-full max-w-[1280px] px-2">
                            <Contents menuIdx={menuIdx} onMenu={onMenu} />
                        </div>
                    </div>
                </div>
            </div>

            {/* 모달 */}
            <CModal backdrop={"static"}
                    alignment={"center"}
                    visible={pointExchangeOpen}>
                <PointExchange onClose={() => setPointExchangeOpen(false)} />
            </CModal>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.account.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        setMyIp: (ip) => {
            dispatch(accountActions.setMyIp(ip))
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Frame)