import {useState} from "react";
import * as api from "../../api/api";
import {accountActions} from "../../redux/actions/account";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";

const UserRegister = (props) => {
    const navigate = useNavigate();
    const [isUserIdCheck, setIsUserIdCheck] = useState(false)
    const [userInfo, setUserInfo] = useState({
        companyCode: '0000', companyType: '0000', userType: "6000", parentId: "test", status: 9,
        userId: "", password: "", exchangePass: "", userName: "", nickName: "", mobileNo: "", recommendCode: "", bankOwner: "", bankName: "", bankAccount: ""
    })

    const getGUser = () => {
        if (userInfo.userId.length === 0) {
            window.alert("ID를 입력해주세요.")
            return
        }

        api.getGUser(userInfo.userId).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (data.length === 0) {
                    window.alert("사용할 수 있는 ID 입니다.")
                    setIsUserIdCheck(true)
                }
                else {
                    window.alert("이미 사용중인 ID 입니다.")
                    setIsUserIdCheck(false)
                }
            }
            else {
                window.alert(statusText)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {

            })
    }

    const register = () => {
        // 아이디 중복체크 실행여부
        if (!isUserIdCheck) {
            window.alert("아이디 중복체크를 먼저 진행 해주세요.")
            return
        }

        if (window.confirm("가입신청 하시겠어요?")) {
            api.putGUser(userInfo).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    window.alert("등록이 완료되었습니다.")
                    navigate("/")
                }
                else {
                    window.alert(statusText)
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {

                })
        }
    }

    const cancel = () => {
        navigate(-1)
    }

    return (
        <div className="flex justify-center min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md space-y-8 bg-white p-6 rounded-lg shadow-md">
                {/* 헤더 */}
                <div className="text-center">
                    <h2 className="text-2xl font-bold text-gray-900">회원가입</h2>
                    <p className="mt-2 text-sm text-gray-600">
                        회원가입 시 모든 항목을 정확하게 기재하시기 바랍니다.
                    </p>
                </div>

                {/* 폼 */}
                <div className="space-y-4">
                    {/* 아이디 */}
                    <div>
                        <label htmlFor="userId" className="block text-sm font-medium text-gray-700">아이디</label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                                id="userId"
                                type="text"
                                className="flex-1 min-w-0 block w-full px-3 py-2 rounded-l-md border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="아이디"
                                onChange={(event) => {
                                    setUserInfo((state) => ({
                                        ...state, userId: event.target.value
                                    }))
                                    setIsUserIdCheck(false)
                                }}
                            />
                            <button
                                type="button"
                                className="inline-flex items-center px-3 py-2 border border-l-0 border-gray-300 bg-blue-600 text-sm font-medium rounded-r-md text-white hover:bg-blue-700 focus:outline-none"
                                onClick={getGUser}
                            >
                                중복확인
                            </button>
                        </div>
                        <p className="mt-1 text-xs text-gray-500">영문, 숫자만 입력 가능하며 최소 4자 이상 입력하세요.</p>
                        {isUserIdCheck && <p className="mt-1 text-xs text-green-500">사용 가능한 아이디입니다.</p>}
                    </div>

                    {/* 비밀번호 */}
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">비밀번호</label>
                        <input
                            id="password"
                            type="password"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="비밀번호"
                            onChange={(event) => {
                                setUserInfo((state) => ({
                                    ...state, password: event.target.value
                                }))
                            }}
                        />
                    </div>

                    {/* 환전 비밀번호 */}
                    <div>
                        <label htmlFor="exchangePass" className="block text-sm font-medium text-gray-700">환전 비밀번호</label>
                        <input
                            id="exchangePass"
                            type="password"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="환전 비밀번호"
                            onChange={(event) => {
                                setUserInfo((state) => ({
                                    ...state, exchangePass: event.target.value
                                }))
                            }}
                        />
                        <p className="mt-1 text-xs text-gray-500">4~8자 내외로 입력해주세요. (특수문자 사용불가)</p>
                    </div>

                    {/* 닉네임 */}
                    <div>
                        <label htmlFor="nickName" className="block text-sm font-medium text-gray-700">닉네임</label>
                        <input
                            id="nickName"
                            type="text"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="닉네임"
                            onChange={(event) => {
                                setUserInfo((state) => ({
                                    ...state, nickName: event.target.value, userName: event.target.value
                                }))
                            }}
                        />
                        <p className="mt-1 text-xs text-gray-500">한글, 영문, 숫자만 입력 가능하며, 최소 2자 이상을 입력하세요.</p>
                    </div>

                    {/* 휴대폰번호 */}
                    <div>
                        <label htmlFor="mobileNo" className="block text-sm font-medium text-gray-700">휴대폰번호</label>
                        <input
                            id="mobileNo"
                            type="text"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="휴대폰번호"
                            onChange={(event) => {
                                setUserInfo((state) => ({
                                    ...state, mobileNo: event.target.value
                                }))
                            }}
                        />
                        <p className="mt-1 text-xs text-gray-500">숫자만 입력하세요.</p>
                    </div>

                    {/* 가입코드 */}
                    <div>
                        <label htmlFor="recommendCode" className="block text-sm font-medium text-gray-700">가입코드</label>
                        <input
                            id="recommendCode"
                            type="text"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="가입코드"
                            onChange={(event) => {
                                setUserInfo((state) => ({
                                    ...state, recommendCode: event.target.value
                                }))
                            }}
                        />
                    </div>

                    {/* 환전계좌 설정 */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">환전계좌 설정</label>
                        <div className="mt-1">
                            <input
                                type="text"
                                className="mb-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="예금주"
                                onChange={(event) => {
                                    setUserInfo((state) => ({
                                        ...state, bankOwner: event.target.value
                                    }))
                                }}
                            />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                            <input
                                type="text"
                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="은행명"
                                onChange={(event) => {
                                    setUserInfo((state) => ({
                                        ...state, bankName: event.target.value
                                    }))
                                }}
                            />
                            <input
                                type="text"
                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="계좌번호 (숫자만 입력)"
                                onChange={(event) => {
                                    setUserInfo((state) => ({
                                        ...state, bankAccount: event.target.value
                                    }))
                                }}
                            />
                        </div>
                    </div>
                </div>

                {/* 버튼 */}
                <div className="flex flex-col sm:flex-row gap-3 pt-4">
                    <button
                        type="button"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
                        onClick={register}
                    >
                        가입하기
                    </button>
                    <button
                        type="button"
                        className="group relative w-full flex justify-center py-2 px-4 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                        onClick={cancel}
                    >
                        취소
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(UserRegister)