import React, { useState, useEffect, useCallback, memo, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import * as api from '../../../api/api';
import { userNotesActions } from '../../../redux/actions/usernotes';
import { CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react';

// 날짜 포맷팅 함수를 컴포넌트 외부로 이동
const formatDateString = (dateString) => {
    if (!dateString) return '';
    
    try {
        const date = new Date(dateString.replace(' ', 'T'));
        return date.toLocaleString('ko-KR', {
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit',
            hour: '2-digit', 
            minute: '2-digit'
        });
    } catch (e) {
        return dateString;
    }
};

// memo를 사용하여 불필요한 리렌더링 방지
const UserNoteDetail = memo(({ note, onClose, onRead, setUnreadCount }) => {
    const { user } = useSelector(state => state.account);
    const { unreadCount } = useSelector(state => state.userNotes);
    
    const [loading, setLoading] = useState(true);
    const [detailData, setDetailData] = useState(null);
    
    // 여러 참조값을 useRef로 관리하여 의존성 배열 최적화
    const noteRef = useRef(note);
    const unreadCountRef = useRef(unreadCount);
    const processedRef = useRef(false); // 읽음 처리가 이미 수행되었는지 추적
    const apiCallInProgressRef = useRef(false); // API 호출 중복 방지
    
    // 값이 변경될 때 ref 업데이트
    useEffect(() => {
        noteRef.current = note;
    }, [note]);
    
    useEffect(() => {
        unreadCountRef.current = unreadCount;
    }, [unreadCount]);
    
    // 읽음 상태 처리 로직을 별도 함수로 분리
    const processReadStatus = useCallback(async () => {
        // 이미 처리됐거나 API 호출 중이거나 이미 읽은 쪽지면 건너뜀
        if (processedRef.current || apiCallInProgressRef.current || noteRef.current.readYn === 1) {
            return;
        }
        
        apiCallInProgressRef.current = true;
        
        try {
            // 읽음 상태 업데이트 API 호출
            await api.updateNoteReadStatus(noteRef.current.seq, user.userId);
            
            // 읽음 상태 처리 완료 표시
            processedRef.current = true;
            
            // 읽지 않은 쪽지 수 감소
            if (unreadCountRef.current > 0) {
                setUnreadCount(unreadCountRef.current - 1);
            }
            
            // 부모 컴포넌트에 읽음 상태 변경 알림 (선택적)
            if (onRead) {
                onRead(noteRef.current.seq);
            }
        } catch (error) {
            console.error('읽음 상태 업데이트 실패:', error);
        } finally {
            apiCallInProgressRef.current = false;
        }
    }, [user, setUnreadCount, onRead]);
    
    // 쪽지 상세 정보 조회
    const fetchNoteDetail = useCallback(async () => {
        if (!noteRef.current || !noteRef.current.seq) return;
        
        setLoading(true);
        try {
            const response = await api.getNoteDetail(noteRef.current.seq);
            const { data, status } = response;
            
            if (status === 200 && data && data.data) {
                setDetailData(data.data);
                
                // 읽지 않은 쪽지인 경우 읽음 상태로 업데이트
                if (noteRef.current.readYn === 0) {
                    // 비동기로 읽음 상태 처리 (UI 블로킹 방지)
                    setTimeout(() => {
                        processReadStatus();
                    }, 0);
                }
            }
        } catch (error) {
            console.error('쪽지 상세 조회 오류:', error);
        } finally {
            setLoading(false);
        }
    }, [processReadStatus]);
    
    // 컴포넌트 마운트 시 한 번만 실행
    useEffect(() => {
        fetchNoteDetail();
        // 컴포넌트 언마운트 시 정리 작업
        return () => {
            processedRef.current = false;
            apiCallInProgressRef.current = false;
        };
    }, [fetchNoteDetail]);
    
    // 쪽지 삭제 처리
    const handleDelete = useCallback(async () => {
        if (!window.confirm('쪽지를 삭제하시겠습니까?')) return;
        
        try {
            const response = await api.deleteNote(noteRef.current.seq, user.userId);
            
            if (response.status === 200) {
                window.alert('쪽지가 삭제되었습니다.');
                
                // 읽지 않은 쪽지였는데 아직 읽음 처리가 되지 않은 경우
                if (noteRef.current.readYn === 0 && !processedRef.current) {
                    if (unreadCountRef.current > 0) {
                        setUnreadCount(unreadCountRef.current - 1);
                    }
                }
                
                // 모달 닫기
                onClose();
            }
        } catch (error) {
            console.error('쪽지 삭제 오류:', error);
            window.alert('쪽지를 삭제하는 도중 오류가 발생했습니다.');
        }
    }, [user, onClose, setUnreadCount]);
    
    // 로딩 표시 컴포넌트
    const LoadingSpinner = () => (
        <div className="flex justify-center items-center p-8">
            <div className="w-10 h-10 border-4 border-blue-200 border-t-blue-500 rounded-full animate-spin"></div>
        </div>
    );
    
    return (
        <>
            <CModalHeader closeButton={false}>
                <CModalTitle>쪽지 내용</CModalTitle>
            </CModalHeader>
            <CModalBody>
                {loading ? (
                    <LoadingSpinner />
                ) : detailData ? (
                    <div className="space-y-4">
                        <div className="bg-gray-50 p-4 rounded-lg">
                            <div className="grid grid-cols-3 gap-4 mb-2">
                                <div className="text-gray-600 font-medium">제목</div>
                                <div className="col-span-2">{detailData.subject}</div>
                            </div>
                            <div className="grid grid-cols-3 gap-4 mb-2">
                                <div className="text-gray-600 font-medium">보낸 날짜</div>
                                <div className="col-span-2">{formatDateString(detailData.instTime)}</div>
                            </div>
                            <div className="grid grid-cols-3 gap-4">
                                <div className="text-gray-600 font-medium">보낸 사람</div>
                                <div className="col-span-2">{detailData.sender || '시스템'}</div>
                            </div>
                        </div>
                        
                        <div className="mt-4 p-4 bg-white border border-gray-200 rounded-lg min-h-[120px]">
                            <div className="whitespace-pre-wrap">{detailData.contents}</div>
                        </div>
                    </div>
                ) : (
                    <div className="text-center p-4 text-gray-500">
                        쪽지를 불러올 수 없습니다.
                    </div>
                )}
            </CModalBody>
            <CModalFooter>
                <button
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 mr-2"
                    onClick={handleDelete}
                >
                    삭제
                </button>
                <button
                    className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                    onClick={onClose}
                >
                    닫기
                </button>
            </CModalFooter>
        </>
    );
});

// mapDispatchToProps를 객체 형태로 사용하여 불필요한 함수 생성 방지
const mapDispatchToProps = {
    setUnreadCount: userNotesActions.setUnreadCount
};

export default connect(null, mapDispatchToProps)(UserNoteDetail); 