import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    siteConfig: null,
    loading: false,
    error: null
};

const siteConfigSlice = createSlice({
    name: 'siteConfig',
    initialState,
    reducers: {
        setSiteConfig: (state, action) => {
            state.siteConfig = action.payload;
            state.loading = false;
            state.error = null;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export const {
    setSiteConfig,
    setLoading,
    setError
} = siteConfigSlice.actions;

export default siteConfigSlice.reducer; 