import {useEffect, useState} from "react";
import Loading from "../../component/Loading";
import FreeMatch from "./FreeMatch";

const Contents = ({props, title, onCart, data}) => {
    const [loading, setLoading] = useState(false);
    return (
        <div className={"overflow-y-auto"}>
            {loading ? <Loading /> : null}
            <FreeMatch id={props.id} name={props.name} onCart={onCart} data={data} />
        </div>
    )
}

export default Contents