import {useEffect, useState} from "react";
import {TrashIcon} from "@heroicons/react/24/outline";
import numeral from "numeral";
import {connect, useSelector} from "react-redux";
import * as BizUtil from "../../util/BizUtil"
import dayjs from "dayjs";

const minigameSetting = {
    startTime: "04:30",
    repeatMinute: 5,
    bettingAvailableSecond: 10
}

const MinigameCart = (props) => {
    const {user} = useSelector((props) => props.account);
    const [bettingInfo, setBettingInfo] = useState({
        minimumBet: 5000, maximumBet: 10000000, maximumWinning: 999999999, expectWinning: 0
    })
    const [bettingPrice, setBettingPrice] = useState(0)
    const [oddPer, setOddPer] = useState(1)
    const [remainTime, setRemainTime] = useState('');
    const [isBettingAvailable, setIsBettingAvailable] = useState(true);
    const [bettingEndTime, setBettingEndTime] = useState('');

    useEffect(() => {
        props.onBettingAmount(bettingPrice)
        calc()
    }, [bettingPrice])
    
    useEffect(() => {
        if (props.selectedHole) {
            // 배당율을 소수점 2자리까지 표시
            setOddPer(Number(props.selectedHole.holeOdds).toFixed(2));
        } else {
            setOddPer(1);
        }
    }, [props.selectedHole])

    useEffect(() => {
        const calculateNextGame = () => {
            const now = dayjs();
            const baseMinutes = 4;
            const baseSeconds = 30;
            const intervalMinutes = 5;
            
            const currentMinute = now.minute();
            const remainder = currentMinute % intervalMinutes;
            
            let nextGameMinutes;
            if (remainder < baseMinutes) {
                nextGameMinutes = Math.floor(currentMinute / intervalMinutes) * intervalMinutes + baseMinutes;
            } else {
                nextGameMinutes = Math.ceil(currentMinute / intervalMinutes) * intervalMinutes + baseMinutes;
            }

            let nextGameHour = now.hour();
            
            if (nextGameMinutes >= 60) {
                nextGameMinutes = nextGameMinutes - 60;
                nextGameHour = (nextGameHour + 1) % 24;
            }

            // 다음 게임 시간 설정 (한 번만 생성)
            const nextGame = dayjs().hour(nextGameHour).minute(nextGameMinutes).second(baseSeconds).millisecond(0);
            
            // 현재 시간이 다음 게임 시간을 지났다면 5분 추가
            const finalNextGame = now.isAfter(nextGame) 
                ? nextGame.add(intervalMinutes, 'minute')
                : nextGame;

            // 배팅 마감 시간 (게임 시작 10초 전)
            const bettingEndTime = finalNextGame.subtract(minigameSetting.bettingAvailableSecond, 'second');
            
            // 게임의 배팅 가능 시작 시간 (게임 시작 22초 후)
            const prevGame = finalNextGame.subtract(intervalMinutes, 'minute');
            const prevGameBettingAvailable = prevGame.add(15, 'second');

            // 배팅 가능 여부 계산
            const isBetweenGames = now.isAfter(prevGameBettingAvailable) && now.isBefore(bettingEndTime);
            setIsBettingAvailable(isBetweenGames);

            // 남은 시간 계산
            const diffMs = finalNextGame.diff(now);
            const diffMinutes = Math.floor(diffMs / 1000 / 60);
            const diffSeconds = Math.floor((diffMs / 1000) % 60);

            // 배팅 마감까지 남은 시간 계산
            const diffToBettingEndMs = bettingEndTime.diff(now);
            const diffToBettingEndMinutes = Math.floor(diffToBettingEndMs / 1000 / 60);
            const diffToBettingEndSeconds = Math.floor((diffToBettingEndMs / 1000) % 60);
            
            setRemainTime(`${String(diffMinutes).padStart(2, '0')}:${String(diffSeconds).padStart(2, '0')}`);
            setBettingEndTime(
                isBetweenGames
                    ? `${String(diffToBettingEndMinutes).padStart(2, '0')}:${String(diffToBettingEndSeconds).padStart(2, '0')}`
                    : '00:00'
            );
        };

        calculateNextGame();
        const timer = setInterval(calculateNextGame, 1000);
        return () => clearInterval(timer);
    }, []);

    const init = () => {
        setBettingPrice(0)
        setOddPer(1)
        // 초기화 시 예상당첨금액도 초기화
        setBettingInfo(prev => ({
            ...prev,
            expectWinning: 0
        }))
        props.init()
    }

    const bettingAmount = (amount) => {
        const {info} = props;
        let totalAmount = bettingPrice + parseInt(amount);
        if (totalAmount > user.userCash) {
            // 내가 가지고 있는 금액 이상은 안됨
            totalAmount = user.userCash
        }
        setBettingPrice(totalAmount)
    }

    const clear = () => {
        setBettingPrice(0)
        // 금액 초기화 시 예상당첨금액도 초기화
        setBettingInfo(prev => ({
            ...prev,
            expectWinning: 0
        }))
    }

    const calc = () => {
        // 예상 당첨금액을 구해주자
        let expectAmount = Math.round(bettingPrice * oddPer * 100) / 100;
        // 예상 금액이 최대 금액을 넘으면 최대 금액으로
        if (expectAmount > bettingInfo.maximumWinning) {
            expectAmount = bettingInfo.maximumWinning
        }
        setBettingInfo(prev => ({
            ...prev,
            expectWinning: expectAmount
        }))
    }

    const onBetting = () => {
        if (!props.selectedHole) {
            window.alert("배팅 내역이 없습니다.")
            return
        }

        if (bettingPrice <= 0) {
            window.alert("배팅 금액을 입력해주세요.")
            return
        }

        // 베팅 데이터 구성
        const betting = {
            companyCode: user.companyCode,
            bettingDt: dayjs().format('YYYYMMDD'),
            bettingUserId: user.userId,
            minigameType: props.selectedHole.id,
            minigameName: props.selectedHole.name,
            gameType: props.selectedHole.gameType,
            gameName: props.selectedHole.gameName,
            holeType: props.selectedHole.hole,
            holeName: props.selectedHole.holeName,
            holeOdds: props.selectedHole.holeOdds,
            rollingType: props.selectedHole.rollingType,
            bettingAmount: bettingPrice
        }
        props.onBetting(betting)
    }

    return (
        <div className={"w-full flex flex-col p-2 bg-white border-l border-gray-200 min-h-full"}>
            <div className={"flex flex-row p-2 justify-between bg-gray-50 rounded-lg border border-gray-200"}>
                <div className={"flex flex-row gap-2 items-center"}>
                    <span className={"text-gray-600 text-sm"}>전체</span>
                    <span className={"text-blue-600 text-sm font-bold"}>{props.selectedHole ? 1 : 0}</span>
                </div>
                <button className={"rounded text-gray-600 hover:text-gray-800"} onClick={init}>
                    <TrashIcon className="h-4 w-4" />
                </button>
            </div>
            <div className={"flex flex-col py-2 mb-2 gap-2"}>
                <div className={"flex flex-row justify-between items-center"}>
                    <span className={"w-32 text-xs text-gray-600"}>게임분류</span>
                    <span className={"text-xs text-blue-600 font-medium"}>
                        {props.selectedGame?.name || '미선택'}
                    </span>
                </div>
                <div className={"flex flex-row justify-between items-center"}>
                    <span className={"text-xs text-gray-600"}>게임선택</span>
                    <span className={"text-xs text-blue-600 font-medium"}>
                        {props.selectedHole ? `${props.selectedHole.gameName}(${props.selectedHole.holeName})` : ''}
                    </span>
                </div>
                <div className={"flex flex-row justify-between items-center"}>
                    <span className={"text-xs text-gray-600"}>보유금액</span>
                    <span className={"text-xs text-blue-600 font-medium"}>{numeral(user.userCash).format("0,0")} 원</span>
                </div>
                <div className={"flex flex-row justify-between items-center"}>
                    <span className={"text-xs text-gray-600"}>배당율</span>
                    <span className={"text-xs text-blue-600 font-medium"}>{props.selectedHole ? `x ${oddPer}` : 'x 1.00'}</span>
                </div>
            </div>
            <div className="relative flex-1">
                <div className={"flex flex-col"}>
                    <div className={"flex flex-col mb-2 divide-y divide-gray-100 text-[13px] md:text-sm border"}>
                        <InfoRow label="배당율" value={`x ${oddPer}`} />
                        <InfoRow label="최소배팅금액" value={`${numeral(bettingInfo.minimumBet).format("0,0")} 원`} />
                        <InfoRow label="최대배팅금액" value={`${numeral(bettingInfo.maximumBet).format("0,0")} 원`} />
                        <InfoRow label="최대당첨금액" value={`${numeral(bettingInfo.maximumWinning).format("0,0")} 원`} />
                        <InfoRow label="예상당첨금액" value={`${numeral(bettingInfo.expectWinning).format("0,0")} 원`} />
                    </div>
                    <div className={"flex flex-row p-2 items-center bg-gray-100 mb-2"}>
                        <span className={"text-xs text-gray-700"}>배팅금액</span>
                        <input 
                            className={"flex-1 px-2 text-end bg-transparent text-blue-600 text-sm font-bold focus:outline-none"}
                            onChange={(e) => {
                                const amount = Number(BizUtil.removeComma(e.target.value))
                                setBettingPrice(amount)
                            }}
                            value={numeral(bettingPrice).format("0,0")}
                        />
                    </div>
                    <div className={"flex flex-col gap-1 mb-3"}>
                        <div className={"grid grid-cols-4 md:grid-cols-3 gap-1"}>
                            <AmountButton amount={5000} onClick={() => bettingAmount(5000)} />
                            <AmountButton amount={10000} onClick={() => bettingAmount(10000)} />
                            <AmountButton amount={50000} onClick={() => bettingAmount(50000)} />
                        </div>
                        <div className={"grid grid-cols-4 md:grid-cols-3 gap-1"}>
                            <AmountButton amount={100000} onClick={() => bettingAmount(100000)} />
                            <AmountButton amount={300000} onClick={() => bettingAmount(300000)} />
                            <AmountButton amount={500000} onClick={() => bettingAmount(500000)} />
                        </div>
                        <div className={"grid grid-cols-3 gap-1"}>
                            <ActionButton text="초기화" onClick={clear} />
                            <ActionButton text="최대금액" onClick={() => bettingAmount(99999999)} />
                            <ActionButton text="최소금액" onClick={() => bettingAmount(5000)} />
                        </div>
                    </div>

                    <div className="flex flex-col gap-1 mb-2 p-2 bg-gray-50 rounded-lg">
                        <div className="flex flex-row justify-between items-center">
                            <span className="text-xs text-gray-600">남은 시간</span>
                            <span className="text-blue-600 font-bold">{remainTime}</span>
                        </div>
                        <div className="flex flex-row justify-between items-center">
                            <span className="text-xs text-gray-600">배팅 마감</span>
                            <span className={`font-bold ${isBettingAvailable ? 'text-blue-600' : 'text-red-500'}`}>
                                {isBettingAvailable ? `${bettingEndTime}` : '마감'}
                            </span>
                        </div>
                    </div>

                    <button 
                        className={"py-2 bg-blue-500 hover:bg-blue-600 rounded-lg text-white text-sm font-bold transition-colors"}
                        onClick={onBetting}
                    >
                        배팅하기
                    </button>
                </div>

                {!isBettingAvailable && (
                    <div className="absolute inset-0 bg-white/80 flex items-center justify-center">
                        <div className="text-lg font-bold text-red-500">
                            배팅이 마감되었습니다.
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const InfoRow = ({ label, value }) => (
    <div className={"flex flex-row p-2 items-center justify-between"}>
        <span className={"text-xs text-gray-600"}>{label}</span>
        <span className={"text-xs text-blue-600 font-bold"}>{value}</span>
    </div>
);

const AmountButton = ({ amount, onClick }) => (
    <button 
        className={"py-2 text-gray-700 text-xs bg-gray-50 hover:bg-gray-100 border border-gray-200 transition-colors"} 
        onClick={onClick}
    >
        {numeral(amount).format("0,0")}
    </button>
);

const ActionButton = ({ text, onClick }) => (
    <button 
        className={"py-2 text-gray-700 text-xs bg-gray-50 hover:bg-gray-100 border border-gray-200 transition-colors"} 
        onClick={onClick}
    >
        {text}
    </button>
);

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(MinigameCart)