import React, {useState, useCallback, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import * as api from "../../../api/api";

const PointExchange = ({ onClose }) => {
    const { user } = useSelector(state => state.account);
    const { siteConfig } = useSelector(state => state.siteConfig);
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);
    const closeButtonRef = useRef(null);

    useEffect(() => {
        if (siteConfig) {
            // 전환 가능한 최대 금액 계산
            const unit = parseInt(siteConfig.pointUnit || 0);
            const maxPoint = parseInt(user?.userPoint || 0);
            const maxPossibleAmount = Math.floor(maxPoint / unit) * unit;
            
            // 최소 교환 포인트와 비교하여 더 큰 값을 초기값으로 설정
            const minPoint = parseInt(siteConfig.minExchangePoint || 0);
            const initialAmount = Math.max(minPoint, maxPossibleAmount);
            
            setAmount(initialAmount.toString());
        }
    }, [siteConfig, user?.userPoint]);

    const handleClose = useCallback(() => {
        // 모달이 닫히기 전에 포커스를 모달 외부로 이동
        if (closeButtonRef.current) {
            closeButtonRef.current.blur();
        }
        onClose();
    }, [onClose]);

    const validateAndAdjustAmount = useCallback((value) => {
        if (!value) return '';
        if (!siteConfig?.pointUnit || !siteConfig?.minExchangePoint) return value;
        
        const numValue = parseInt(value);
        if (isNaN(numValue)) return value;
        
        const unit = parseInt(siteConfig.pointUnit);
        const minPoint = parseInt(siteConfig.minExchangePoint);
        const maxPoint = parseInt(user?.userPoint || 0);
        
        // 최소값보다 작은 경우 최소값으로 조정
        if (numValue < minPoint) {
            return minPoint.toString();
        }
        
        // 최대 가능 금액 계산 (보유포인트를 초과하지 않는 가장 큰 배수)
        const maxPossibleAmount = Math.floor(maxPoint / unit) * unit;
        
        // 최대값보다 큰 경우 최대 가능 금액으로 조정
        if (numValue > maxPossibleAmount) {
            return maxPossibleAmount.toString();
        }
        
        // 최소값보다 큰 경우에만 배수 검증
        if (numValue >= minPoint) {
            const remainder = numValue % unit;
            if (remainder === 0) return value;
            
            // 가장 가까운 배수로 조정
            const adjustedValue = Math.round(numValue / unit) * unit;
            // 조정된 값이 최대 가능 금액을 초과하지 않도록 함
            return Math.min(adjustedValue, maxPossibleAmount).toString();
        }
        
        return value;
    }, [siteConfig?.pointUnit, siteConfig?.minExchangePoint, user?.userPoint]);

    const handleAmountChange = useCallback((e) => {
        const value = e.target.value;
        
        // 빈 값이거나 숫자만 있는 경우에만 허용
        if (value === '' || /^\d+$/.test(value)) {
            setAmount(value);
        }
    }, []);

    const handleAmountBlur = useCallback((e) => {
        const value = e.target.value;
        if (value === '') return;
        
        const numValue = parseInt(value);
        if (isNaN(numValue)) return;
        
        const unit = parseInt(siteConfig?.pointUnit || 0);
        const minPoint = parseInt(siteConfig?.minExchangePoint || 0);
        const maxPoint = parseInt(user?.userPoint || 0);
        
        // 최소값보다 작은 경우 최소값으로 설정
        if (numValue < minPoint) {
            setAmount(minPoint.toString());
            return;
        }
        
        // 최대 가능 금액 계산 (보유포인트를 초과하지 않는 가장 큰 배수)
        const maxPossibleAmount = Math.floor(maxPoint / unit) * unit;
        
        // 최대값보다 큰 경우 최대 가능 금액으로 설정
        if (numValue > maxPossibleAmount) {
            setAmount(maxPossibleAmount.toString());
            return;
        }
        
        // 배수 검증 및 조정
        const remainder = numValue % unit;
        if (remainder === 0) return;
        
        // 가장 가까운 배수로 조정
        const adjustedValue = Math.round(numValue / unit) * unit;
        // 조정된 값이 최대 가능 금액을 초과하지 않도록 함
        setAmount(Math.min(adjustedValue, maxPossibleAmount).toString());
    }, [siteConfig?.pointUnit, siteConfig?.minExchangePoint, user?.userPoint]);

    const handleExchange = useCallback(async () => {
        if (!amount || isNaN(amount) || amount <= 0) {
            window.alert('올바른 금액을 입력해주세요.');
            return;
        }

        const numAmount = parseInt(amount);
        const unit = parseInt(siteConfig?.pointUnit);
        
        if (numAmount % unit !== 0) {
            window.alert(`${unit.toLocaleString()}의 배수만 입력 가능합니다.`);
            return;
        }

        setLoading(true);
        try {
            const request = {
                companyCode: user.companyCode,
                userId: user.userId,
                exchangeType: "C",
                amount: amount
            }

            if (window.confirm("포인트 전환을 하시겠어요?")) {
                api.requestExchangePoint(request).then(result => {
                    const {data, status, statusText} = result
                    if (status === 200) {
                        window.alert('포인트 전환이 완료되었습니다.');
                        handleClose();
                    } else {
                        window.alert(statusText)
                    }
                })
            }
        } catch (error) {
            window.alert('포인트 전환 중 오류가 발생했습니다.');
        } finally {
            setLoading(false);
        }
    }, [amount, handleClose, siteConfig?.pointUnit]);

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">포인트 전환</h2>
                    <button 
                        ref={closeButtonRef}
                        onClick={handleClose} 
                        className="text-gray-500 hover:text-gray-700"
                        aria-label="닫기"
                    >
                        ✕
                    </button>
                </div>

                <div className="mb-4">
                    <p className="text-sm text-gray-600 mb-2">보유 포인트</p>
                    <p className="text-lg font-semibold text-blue-600">
                        {numeral(user?.userPoint || 0).format("0,0")}p
                    </p>
                </div>

                <div className="mb-4">
                    <label className="block text-sm text-gray-600 mb-2">
                        전환할 포인트
                    </label>
                    <input
                        value={amount}
                        onChange={handleAmountChange}
                        onBlur={handleAmountBlur}
                        min={siteConfig?.minExchangePoint || 0}
                        max={Math.floor((user?.userPoint || 0) / (siteConfig?.pointUnit || 1)) * (siteConfig?.pointUnit || 1)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <p className="text-xs text-gray-500 mt-1">
                        {siteConfig?.minExchangePoint?.toLocaleString() || 0} 이상, {siteConfig?.pointUnit?.toLocaleString() || 0} 단위로 입력 가능합니다
                    </p>
                </div>

                <div className="flex justify-end gap-2">
                    <button
                        onClick={handleClose}
                        className="px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
                    >
                        취소
                    </button>
                    <button
                        onClick={handleExchange}
                        disabled={loading}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-blue-300"
                    >
                        {loading ? '처리중...' : '전환하기'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PointExchange;