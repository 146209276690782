import {connect, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import * as api from "../../../api/api";
import Loading from "../../../component/Loading";
import numeral from "numeral";

const UserWithdrawList = (props) => {
    const {user} = useSelector((props) => props.account);
    const [loading, setLoading] = useState(false)
    const [withdrawList, setWithdrawList] = useState([])

    useEffect(() => {
        getUserDepositList()
    }, [])

    const getUserDepositList = () => {
        const params = {
            companyCode: user.companyCode,
            userId: user.userId
        }

        setLoading(true)
        api.getWithdrawList(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setWithdrawList(data)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="flex-1 p-4">
            {loading ? <Loading/> : null}
            {withdrawList.length > 0 && (
                <div className="bg-white rounded-lg shadow overflow-hidden">
                    {/* 테이블 헤더 */}
                    <div className="bg-gray-50 border-b">
                        <div className="grid grid-cols-4 px-6 py-2">
                            <span className="text-sm font-semibold text-gray-700 text-center">신청일</span>
                            <span className="text-sm font-semibold text-gray-700 text-center">신청금액</span>
                            <span className="text-sm font-semibold text-gray-700 text-center">상태</span>
                            <span className="text-sm font-semibold text-gray-700 text-center">처리일시</span>
                        </div>
                    </div>
                    
                    {/* 테이블 바디 */}
                    <div className="divide-y divide-gray-200">
                        {withdrawList.map((item, index) => (
                            <div 
                                key={index} 
                                className="grid grid-cols-4 gap-4 px-6 py-2 hover:bg-gray-50 transition-colors duration-150"
                            >
                                <span className="text-sm text-gray-600 text-center">
                                    {item.instTime}
                                </span>
                                <span className="text-sm text-gray-900 font-medium justify-self-end pr-4">
                                    {numeral(item.amount).format("0,0")}
                                </span>
                                <span className={`text-sm pl-4 text-center ${
                                    item.status === 9 ? 'text-green-600' :
                                    item.status === 8 ? 'text-red-600' :
                                    item.status === 0 ? 'text-blue-600' :
                                    item.status === 1 ? 'text-red-600' :
                                    'text-gray-600'
                                }`}>
                                    {item.status === 9 ? '완료' :
                                     item.status === 8 ? '거절' :
                                     item.status === 0 ? '대기' :
                                     item.status === 1 ? '거절' :
                                     '알 수 없음'}
                                </span>
                                <span className="text-sm text-gray-600 text-center">
                                    {item.updTime}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(UserWithdrawList)