import {connect, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getMiracleGames, getMiracleVendors, requestGameLaunch} from "../../api/api";
import Loading from "../../component/Loading";

const CasinoMain = (props) => {
    const {user} = useSelector((props) => props.account);
    const [loading, setLoading] = useState(false)
    const [vendorList, setVendorList] = useState([])
    const [selectedVendorCode, setSelectedVendorCode] = useState("")
    const [gameList, setGameList] = useState([])
    const [selectedGameCode, setSelectedGameCode] = useState("")
    const [step, setStep] = useState(0)

    useEffect(() => {
        setStep(0)
        search()
    }, [])

    const search = () => {
        const params = {
            companyCode: user.companyCode
        }

        setLoading(true)
        getMiracleVendors(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (data.success) {
                    const resultData = data.data
                    // 카지노 벤더만 filter
                    const casinoFilterData = resultData.filter(item => item.type_code === 'casino')
                    setVendorList(casinoFilterData)
                }
            }
        })
            .catch(ex => {

            })
            .finally(() => setLoading(false))
    }

    const getGames = (vendorCode) => {
        const params = {
            companyCode: user.companyCode,
            vendorCode: vendorCode
        }

        setLoading(true)
        getMiracleGames(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (data.success) {
                    setStep(1)
                    const resultData = data.data

                    setSelectedVendorCode(vendorCode);
                    setGameList(resultData)
                }
            }
        })
            .catch(ex => {

            })
            .finally(() => setLoading(false))
    }

    const launch = (vendorCode, gameCode) => {
        const params = {
            companyCode: user.companyCode,
            userId: user.userId,
            gameType: "casino",
            vendorCode: vendorCode,
            gameCode: "lobby",
        }

        setLoading(true)
        requestGameLaunch(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (data.success) {
                    const resultData = data.data;
                    enter(resultData.url)
                }
                else {
                    window.alert(data.message)
                }
            }
        })
            .catch(ex => {

            })
            .finally(() => setLoading(false))
    }

    const enter = (url) => {
        window.open(url, '_blank');
    }

    return (
        <div className="w-full h-full min-h-[640px] bg-white rounded-lg p-4">
            {loading ? <Loading/> : null}
            {/* 벤더 영역 */}
            {step === 0 &&
            <div className={"grid grid-cols-4"}>
                {vendorList.map((item, idx) => {
                    return (
                        <button key={String(idx)} className={"flex flex-col p-3 rounded bg-blue-100 gap-2"}
                                // onClick={() => getGames(item.code)}
                            onClick={() => launch(item.code, "lobby")}
                        >
                            <img className={"h-32"} src={null} alt={""}/>
                            <span className={"text-sm font-bold"}>
                                {item.names["ko"]}
                            </span>
                        </button>
                    )
                })}
            </div>
            }

            {/* 게임 영역 */}
            {step === 1 &&
            <div className={"grid grid-cols-4 gap-2"}>
                <button className={"rounded p-2 bg-gray-500 font-bold text-white"} onClick={() => {
                    setStep(0)
                    setSelectedVendorCode("")
                }}>{"이전"}</button>
                {gameList.map((item, idx) => {
                    return (
                        <button key={String(idx)} className={"flex flex-col p-3 rounded bg-blue-100 gap-2"}
                                onClick={() => launch(item.code)}
                        >
                            <img className={"h-32"} src={item.thumbnail} alt={""}/>
                            <span className={"text-sm font-bold"}>
                                {item.names["ko"]}
                            </span>
                        </button>
                    )
                })}
            </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(CasinoMain)