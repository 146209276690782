import {useEffect, useState} from "react";
import UserDepositRequest from "../deposit/UserDepositRequest";
import UserDepositList from "../deposit/UserDepositList";
import UserWithdrawRequest from "./UserWithdrawRequest";
import UserWithdrawList from "./UserWithdrawList";

const UserWithdraw = (props) => {
    const [activeTab, setActiveTab] = useState('request');

    return (
        <div className={"flex flex-col bg-white"}>
            <img src={"https://14-ca.com/img/lang/kr/img_title_charge1.jpg"} alt={""}/>
            <div className={"flex flex-row p-2 justify-between border-b"}>
                <div className={"flex flex-row"}>
                    <span className={"text-sm text-gray-500 font-bold"}>{"환전 >> 보유머니 환전"}</span>
                </div>
            </div>
            {/* 탭 메뉴 */}
            <div className="border-b">
                <div className="flex">
                    <button
                        className={`px-4 py-2 text-sm font-medium ${
                            activeTab === 'request'
                                ? 'text-blue-600 border-b-2 border-blue-600'
                                : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('request')}
                    >
                        환전신청
                    </button>
                    <button
                        className={`px-4 py-2 text-sm font-medium ${
                            activeTab === 'history'
                                ? 'text-blue-600 border-b-2 border-blue-600'
                                : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setActiveTab('history')}
                    >
                        환전내역
                    </button>
                </div>
            </div>

            {/* 탭 컨텐츠 */}
            <div className={"mt-4"}>
                {activeTab === 'request' && <UserWithdrawRequest />}
                {activeTab === 'history' && <UserWithdrawList />}
            </div>
        </div>
    )
}

export default UserWithdraw